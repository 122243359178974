import React from 'react';

const LiabilityRelease = props => (
  <div className="LiabilityRelease">
    <p>
      In consideration of being permitted to participate in activities and events sponsored by the Los Angeles LGBT 
      Center ("Center"), agree to the following:
    </p>  
    <ol>
      <li>
        <p>
          I agree to comply with all directions and policies of Center activities and events, the organizers and the facility. I understand that Center activities and events are potentially hazardous, and that accidents during the activities and events could lead to serious injury, death and/or property damage, both to me and to others. Risks associated with Center activities and events may include, but are not limited to:
        </p>
        <ul>
          <li>using public streets and facilities where hazards such as broken pavement and road debris may exist;</li>
          <li>accidents arising out of transportation to and from Center activities and events, including but not limited to being struck by, or colliding with, other participants, spectators, automobiles, road and sand debris;</li>
          <li>dehydration, heat stroke, over-exertion, exhaustion, cramps, soft tissue injuries and/or broken bones;</li>
          <li>participating with individuals of all skill levels, including both experienced and novice participants;</li>
          <li>negligence or carelessness of the Center, sponsors, employees, volunteers, participating groups, communities, members of the medical team, and owners/lessors of the property or facility owners where Center activities and events take place (which may include state and local governmental entities);</li>
          <li>negligence or carelessness in the implementation or enforcement of any rules, regulations or guidelines related to Center activities and events and/or in the selection, use or maintenance of any equipment, course, competition, facility or service related to Center activities and events;</li>
          <li>accidents and losses arising out of the use of Center facilities, including public showers and laundry rooms; and</li>
          <li>the Center's provision of transportation to and from various locations.</li>
        </ul>
        <p>
          I understand that Center activities and events may expose me to risks other than those listed above and that the risks 
          may not be reasonably foreseeable to me or to the Center.  In consideration for being allowed to participate in Center 
          activities and events, I hereby assume all risks associated with Center activities and events, even those risks which 
          are not reasonably foreseeable.
        </p>
      </li>
      <li>
        <p>
          For good and valuable consideration, the receipt and sufficiency of which is acknowledged, I hereby fully discharge and forever release the Center, and all of its respective agents, partners, employees, representatives, shareholders, officers, directors, successors and assigns (collectively, the "Releasees"), of and from any and all claims, demands, losses, damages, actions or causes of action whatsoever, whether known or unknown, whether at law or in equity, which I or my heirs, executors, administrators, successors or assigns may now or hereafter have against any of the Releasees in connection with my participation in any Center events and activities, notwithstanding that such claim, damage, action or cause of action may have been contributed to by the negligent acts or admissions of any of the Releasees or otherwise while I am participating in the activities or events.
        </p>
        <p>
          I hereby expressly waive and relinquish any and all rights, defenses and benefits I may have now or in the future under the provisions of California Civil Code section 1542, which provides as follows: <strong>"A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY."</strong>
        </p>
      </li>
      <li>
        Without limiting the generality of the foregoing, I acknowledge that the Releasees will not be responsible for any injury, accident, or any other loss, damage or expense relating to or arising out of my attending any Center activity or event, including, without limitation, in respect of any medical care or treatment that Releasees, or any persons authorized by any of them, may administer to me in the event of an injury or illness during any activity or event.
      </li>
      <li>
        I hereby agree to indemnify, defend and hold harmless the Releasees from all liabilities, claims, costs, expenses, damages, losses and obligations, of any kind or nature (whether in law or in equity), which may arise or result (either directly or indirectly) from my participation in Center activities or events.
      </li>
      <li>
        I expressly agree that the foregoing release and waiver and assumption of the risk are intended to be as broad and inclusive as permitted by California law.  I acknowledge that I have read the foregoing and that I am aware of the legal consequences of this agreement, including that it prevents me from suing the Center, or its employees, agents, directors or officers if I am injured or damaged for any reason due to my participation in Center activities or events.
      </li>
      <li>
        This agreement sets forth the complete agreement between the parties regarding the subject matter contained in the agreement.
      </li>
      <li>
        I acknowledge that this agreement, when completed, must be submitted to the designated Center Representative prior to my participating in any Center activities or events.
      </li>
    </ol>
  </div>
);

export default LiabilityRelease;