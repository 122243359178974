import constants from '../constants';
import { addMessage } from './messages';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';

export const authSetup = () => ({
  type: constants.AUTH_SETUP,
})

export const login = (username, password) => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  const formData = new FormData();
  formData.append('json', JSON.stringify({username, password}));

  const url = handleCiviURLBuild('Authorization','authorize');

  return fetch(url, {
    method: "POST",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }, 
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error) {
     throw new Error(json.error_message);
    }
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const barcodeLogin = (barcode) => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  const formData = new FormData();
  formData.append('json', JSON.stringify({ barcode }));

  const url = handleCiviURLBuild('Authorization','barcodeauthorize');

  return fetch(url, {
    method: "POST",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }, 
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error) {
     throw new Error(json.error_message);
    }
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const logout = () => dispatch => {
  dispatch({
    type: constants.LOGOUT,
  });
}