import React, { Component } from 'react';
import { connect } from 'react-redux';
import { eventRegister } from 'store/actions/save';
import { RSVPStatusDisplay } from 'helpers/CiviCRM';
import statusList from 'helpers/Filter/statuses';
// import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import * as moment from 'moment-timezone';
import ConfirmCancel from './ConfirmCancel';
import EventDetails from './EventDetails';
import locationsList from 'helpers/Filter/locations';

class EventList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      detailsOpen: false,
      detailsEvent: null,

      cancelOpen: false,
      cancelEvent: null,
      cancelContinue: null,

      sortField: 'date_display',
      sortOrder: 1,
    };
  }

  parseListData = data => {

    const { locations, setLocation, statuses } = this.props;
  
    return data && data
      .map((event, i) => {

        const newEvent = {...event};

        // console.log(newEvent)

        newEvent.event_title_display = 
          <div className="fake-link" onClick={() => this.toggleEventDetailsOpen(true, newEvent)}>
            {newEvent.event_title}
          </div>;
        newEvent.rsvp_status_display = 
          <RSVPStatusDisplay
            rsvp_status={event.rsvp_status} 
            onClick={() => this.toggleEventDetailsOpen(true, newEvent)}
          />;

        const location = locationsList.find(v => newEvent.event_location_value===v.value);

        if (location && newEvent.event_location_value!=='Other') {
          newEvent.event_location_display = 
            <div className="fake-link" onClick={() => setLocation(location)}>
              {newEvent.event_location}
            </div>;
        } else if (event.event_location_address.length>0) {
          newEvent.event_location_display = 
            <div 
              className="fake-link"
              onClick={() => {
                setLocation({
                  label: event.event_location,
                  address: event.event_location_address,
                  // Latitude: NA
                  position: null,
                  selected: false,
                  value: 'Other',
                })
              }}>
              {newEvent.event_location}
            </div>;
        } else {
          newEvent.event_location_display = newEvent.event_location;
        }

        return newEvent;
      })
      .filter((event, i) => event.rsvp_status !== 'hide')
      .filter((event, i) => locations.length>0 ? locations.includes(event.custom_1198) : true)
      .filter((event, i) => statuses.length>0 ? statuses.includes(event.rsvp_status_filter) : true);
  }
  
  sortDate = ({ field, order}) => {
    
    const { data } = this.props;
    const listData = this.parseListData(data);

    const sortedData = listData.sort((a,b) => {
      
      const aMoment = moment.tz(a.start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
      const bMoment = moment.tz(b.start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");

      if (aMoment.isSame(bMoment))
        return 0;

      if (aMoment.isBefore(bMoment))
        return -1 * order;

      return order;
    });

    return sortedData;
  }

  sortName = ({ field, order}) => {

    const { data } = this.props;
    const listData = this.parseListData(data);

    const sortedData = listData.sort((a,b) => {
      
      const aTitle = a.event_title.toLowerCase();
      const bTitle = b.event_title.toLowerCase();

      if (aTitle === bTitle)
        return 0;

      return (aTitle > bTitle ? 1 : -1) * order;
    });

    return sortedData;
  }

  sortLocation = ({ field, order}) => {

    const { data } = this.props;
    const listData = this.parseListData(data);

    const sortedData = listData.sort((a,b) => {
      
      const aTitle = a.event_location.toLowerCase();
      const bTitle = b.event_location.toLowerCase();

      if (aTitle === bTitle)
        return 0;

      return (aTitle > bTitle ? 1 : -1) * order;
    });

    return sortedData;
  }

  sortStatus = ({ field, order}) => {
    
    const { data } = this.props;
    const listData = this.parseListData(data);

    const sortedData = listData.sort((a,b) => {
      
      const aStatus = statusList.find(v => v.value === a.rsvp_status_filter);
      const bStatus = statusList.find(v => v.value === b.rsvp_status_filter);

      if (typeof aStatus === 'undefined' && typeof bStatus === 'undefined')
        return 0;

      if (typeof aStatus === 'undefined')
        return -1 * order;

      if (typeof bStatus === 'undefined')
        return 1 * order;

      if (aStatus.label === bStatus.label)
        return 0;

      return (aStatus.label.toLowerCase() > bStatus.label.toLowerCase() ? 1 : -1) * order;
    });

    return sortedData;
  }
  
  toggleEventDetailsOpen = (state=null, detailsEvent=null) => 
    this.setState({ detailsOpen: state===null ? !this.state.detailsOpen : state, detailsEvent })
  toggleConfirmCancelOpen = (state=null, cancelEvent=null, cancelContinue=null) => 
    this.setState({ cancelOpen: state===null ? !this.state.cancelOpen : state, cancelEvent, cancelContinue })

  render() {
    
    const { data, width, mobileMode } = this.props;
    const listData = this.parseListData(data);

    const { cancelOpen, cancelEvent, cancelContinue, detailsOpen, detailsEvent } = this.state;

    return (
      <div className="EventList">
        <DataTable 
          value={listData}
          resizableColumns={true}
          scrollable={width>640} // messed up mobile styling when this is true
          scrollHeight="500px"
          responsive={true}
        >
          <Column 
            field="date_display"
            header="Date and Time"
            sortable={true}
            style={mobileMode ? {} : { width: '25%' }} 
            sortFunction={this.sortDate}
          />
          <Column 
            field="event_title_display"
            header="Event Name"
            sortable={true}
            style={mobileMode ? {} : { width: '25%' }}
            sortFunction={this.sortName}
          />
          <Column
            field="event_location_display"
            header="Location"
            sortable={true}
            sortFunction={this.sortLocation}
          />
          <Column
            field="rsvp_status_display"
            header="RSVP Status"
            sortable={true}
            sortFunction={this.sortStatus}
          />
          {/* <Column
            field="ada_display"
            header="ADA"
            style={{ width: '15%' }}
          /> */}
        </DataTable>
        <EventDetails
          open={detailsOpen}
          event={detailsEvent}
          toggle={this.toggleEventDetailsOpen}
          toggleCancel={this.toggleConfirmCancelOpen}
        />
        <ConfirmCancel
          open={cancelOpen}
          event={cancelEvent}
          toggle={this.toggleConfirmCancelOpen}
          onContinue={cancelContinue} 
        />
      </div>
    );
  }
}

const listName = 'rsvp';
const saveName = 'register';

const mapStateToProps = (state) => {
  const {
    display: { mobileMode, width },
    list: { isFetching },
    load: { statusMap },
    list,
  } = state;
  return {
    mobileMode,
    isFetching,
    ...list[listName],
    statusMap,
    width,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleEventRegister: event => dispatch(eventRegister(saveName, event))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventList);