import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { InputText } from 'components/FormFields';

class UsernamePassword extends Component {

  render() {

    return (
      <div className="UsernamePassword">
        <Field
          label="Username"
          name="auth[username]" 
          // disabled={isFetching}
          component={InputText}
          readOnly={true}
        />
        <h5>Password must be at least 8 characters long with a number and both upper and lower case letters</h5>
        <Field
          label="Password"
          name="auth[password]" 
          // disabled={isFetching}
          type="password"
          component={InputText}
        />
        <Field
          label="Confirm Password"
          name="auth[password_confirmation]" 
          // disabled={isFetching}
          type="password"
          component={InputText}
        />
      </div>
    );
  }
}

const singleName = 'account';


const mapStateToProps = (state) => {
  const single = state.single[singleName];
  return {
    ...single,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(UsernamePassword);