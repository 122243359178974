// Calling it index allows the import to be just import Store from 'store'.
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
import constants from './constants';


const store = createStore(
  reducer,
  // preloadedState,
  composeWithDevTools(
    applyMiddleware(thunk),
  )
);

// Called when the state has changed.
store.subscribe(() => {
  
  const state = store.getState();
  const { 
    auth: {
      loggedIn,
      contactId,
      contact,
      weho,
      apiKey,
      loggedInApiKey,
    },
   } = state;

  // console.log('currenstate', state);
  const storeState = { 
    auth: {
      loggedIn,
      contactId,
      contact,
      weho,
      apiKey,
      loggedInApiKey,
    },
  };

  localStorage.setItem(constants.LOCAL_STORAGE_KEY, JSON.stringify(storeState));
}); 

export default store;