import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

class StillHere extends Component {

  constructor(props){
    super(props);
    this.state = {
      autoLogout: null,
    };
  }

  componentDidMount() {
    
    if (this.props.open)
      this.setupStillHereTimeout();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (!prevProps.open && this.props.open)
      this.setupStillHereTimeout();

    if (prevProps.open && !this.props.open)
      this.clearStillHereTimeout();
  }

  componentWillUnmount() {
    this.clearStillHereTimeout();
  }

  clearStillHereTimeout = () => {

    if (this.state.autoLogout!==null)
      clearTimeout(this.state.autoLogout);
  }

  setupStillHereTimeout = () => {

    if (this.state.autoLogout!==null)
      clearTimeout(this.state.autoLogout);

    const autoLogout = setTimeout(() => this.props.handleLogout(), 30000); // 30 seconds
    this.setState({ autoLogout });
  }

  render() {

    const { contact, handleLogout, open, toggle } = this.props;

    if (!open)
      return null;

    return (
      <Dialog 
        className="StillHere"
        visible={open}
        onHide={() => toggle(false)}
        header={contact===null ? 'Are you still here?' : contact.first_name + ', are you still here?'}
      >
        <div className="p-grid p-justify-center">
          <div className="p-col-fixed">
            <Button onClick={() => handleLogout()} label="No" />
          </div>
          <div className="p-col-fixed">&nbsp;</div>
          <div className="p-col-fixed">
            <Button onClick={() => toggle(false)} label="Yes" className="p-button-warning" />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth: { contact }} = state;
  return {
    contact,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(StillHere);