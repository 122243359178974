const races = [
  {  
    label: 'African-American/Black',
    value: '1',
  },
  {  
    label: 'Asian/Asian American',
    value: '2',
  },
  {  
    label: 'Hispanic/Latino',
    value: '3',
  },
  {  
    label: 'Native American/Alaska Native',
    value: '4',
  },
  {  
    label: 'Native Hawaiian/Pacific Islander',
    value: '5',
  },
  {  
    label: 'White',
    value: '6',
  },
  {  
    label: 'Bi-racial/ Multi-racial',
    value: '7',
  },
];

export default races;