import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import { InputText } from 'components/FormFields';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { Button } from 'primereact/button';
import cx from 'classnames';
import './ResetPassword/styles.css';

class ResetPassword extends Component {

  submitForm = values => {

    const { handleMessage, history, match: { params: { password_reset_key } }, reset } = this.props;

    /**
     * Validate
     */

    const errors = {};

    if (
      values.password.length<8 ||
      !values.password.match(/[a-z]+/) ||
      !values.password.match(/[A-Z]+/) ||
      !values.password.match(/[0-9]+/)
    )
      errors.password = 'Does not match requirements';

    if (!values.password_confirmation) 
      errors.password_confirmation = 'Required';

    if (values.password!==values.password_confirmation)
      errors.password_confirmation = 'Passwords must match';

    const errorKeys = Object.keys(errors);
    if (errorKeys.length>0) {
      throw new SubmissionError(errors);
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    /**
     * Save
     */

    const json = {
      password: values.password,
      password_confirmation: values.password_confirmation,
      password_reset_key,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));

    const url = handleCiviURLBuild('Account', 'reset');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Your password has been reset', 'success');
      reset();
      history.push('/');
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue resetting your password (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { handleSubmit, mobileMode, pristine, submitting } = this.props;    

    return (
      <div className="ResetPassword">
        <div className="p-grid gridContainer">
          <div className ="p-md-4 hasBackground">
            <div className ={cx({ 'droppedCol': !mobileMode })}>
              <h1>Senior Services</h1>
              <p>
                Use this portal to register for 
                events and case management appointments.
              </p>
            </div>
          </div>
          <div className ="p-md-8">
            <div className="fieldContainer">
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
              <hr />
              <Form onSubmit={handleSubmit(this.submitForm)}>
                <h2>Reset Password</h2>
                <h5>Password must be at least 8 characters long with a number and both upper and lower case letters</h5>
                <Field
                  label="Password"
                  name="password" 
                  // disabled={isFetching}
                  type="password"
                  component={InputText}
                />
                <Field
                  label="Confirm Password"
                  name="password_confirmation" 
                  // disabled={isFetching}
                  type="password"
                  component={InputText}
                />
                <div className="form-group">
                  <Button type="submit" className="button" label="Reset Password" disabled={pristine || submitting} />
                </div>
              </Form>
              <hr />
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const formName = 'resetpassword';

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(ResetPassword)
);