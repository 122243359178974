import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Checkbox, RadioButtonGroup } from 'components/FormFields';
import PhotoPermissionDialog from '../Register/PhotoPermissionDialog';
import LiabilityReleaseDialog from '../Register/LiabilityReleaseDialog';
import photoPermissionList from 'helpers/Filter/photoPermissions';

class Releases extends Component {

  constructor(props){
    super(props);
    this.state = {
      photoPermissionDialog: false,
      liabilityReleaseOpen: false,
    };
  }

  togglePhotoPermissionOpen = (state=null) => this.setState({ photoPermissionDialog: state===null ? !this.state.photoPermissionDialog : state });
  toggleLiabilityReleaseOpen = (state=null) => this.setState({ liabilityReleaseOpen: state===null ? !this.state.liabilityReleaseOpen : state });
  
  render() {

    return (
      <div className="Releases">
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label={<span>I agree to the <span className="fake-link" onClick={() => this.togglePhotoPermissionOpen(true)}>Photo Permission</span></span>}
              name="contact[custom_1225]" 
              id="custom_1225"
              // disabled={isFetching}
              component={RadioButtonGroup}
              options={photoPermissionList}
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label={<span>I agree to the <span className="fake-link" onClick={() => this.toggleLiabilityReleaseOpen(true)}>Release of Liability and Participation</span></span>}
              name="contact[custom_1306]"
              id="custom_1306"
              // disabled={isFetching}
              component={Checkbox}
            />
          </div>
        </div>
        <PhotoPermissionDialog open={this.state.photoPermissionDialog} toggle={this.togglePhotoPermissionOpen} />
        <LiabilityReleaseDialog open={this.state.liabilityReleaseOpen} toggle={this.toggleLiabilityReleaseOpen} />
       </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {
  const single = state.single[singleName];
  return {
    ...single,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Releases);