import React from 'react';
import { Button } from 'primereact/button';
// import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { Calendar as PrimeCalendar } from 'components/Calendar';
import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
// import { FileUpload as PrimeFileUpload } from 'primereact/fileupload';
import { InputText as PrimeInputText } from 'primereact/inputtext';
import { InputTextarea as PrimeInputTextArea } from 'primereact/inputtextarea';
import { MultiSelect as PrimeMultiSelect } from 'primereact/multiselect';
import { RadioButton as PrimeRadioButton } from 'primereact/radiobutton';
import * as moment from 'moment-timezone';

export const Calendar = ({ 
  input: { onChange, value, ...inputProps }, 
  id, 
  label, 
  help, 
  dateFormat='M d, yy', 
  hourFormat="12", 
  timeOnly=false, 
  showTime=true, 
  touchUI=false,
  footerTemplate,
  meta: { touched, error }, 
  ...custom
}) => {

  const ref = React.createRef();

  let dateValue = null;
  if (value) {
    dateValue = moment.tz(value, moment.ISO_8601, "America/Los_Angeles");
    if (!dateValue.isValid()) {
      /**
       * Dates not as a JS or ISO date are dropped into here in local timezone :-|
       */
      const parseFormat = (!timeOnly && 'MMM D, YYYY') + ( showTime && !timeOnly && ' ') + (showTime || timeOnly ? ( hourFormat === "12" ? 'HH:mm A' : 'HH:mm' ) : '');
      dateValue = moment(value, parseFormat); // no timezone
    }
  }

  if (!footerTemplate /* && (showTime || timeOnly) */) {
    footerTemplate = () => (
      <div className="p-grid p-justify-center">
        <div className="p-grid-fixed">
          <Button
            type="button"
            label="OK"
            onClick={e => touchUI ? ref.current.disableModality() : ref.current.hideOverlay() }
          />
        </div>
        {/* <div className="p-grid-fixed">&nbsp;</div>
        <div className="p-grid-fixed">
          <Button
            type="button"
            label="Cancel"
            onClick={e => alert('Cancel')}
          />
        </div> */}
      </div>
    );
  }

  // console.log({ inputProps })

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <PrimeCalendar
        ref={ref}
        id={id}
        onChange={e => {
          // console.log(e.target.value)
          const newEvent = {...e};
          const { value } = e;
          if (value) {
            newEvent.target.value = value.toISOString();
            newEvent.value = value.toISOString();
          }
          onChange(newEvent)
        }}
        dateFormat={dateFormat}
        hourFormat={hourFormat}
        timeOnly={timeOnly}
        showTime={showTime}
        footerTemplate={footerTemplate}
        value={dateValue!==null && dateValue.toDate()}
        className={touched && error ? "p-error" : null}
        baseZIndex={1000}
        touchUI={touchUI}
        {...inputProps}
        {...custom} 
        />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const Checkbox = ({ input: { value, ...inputProps }, id, label, help, meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <PrimeCheckbox
        id={id}
        checked={!!value}
        className={touched && error ? "p-error" : null}
        {...inputProps}
        {...custom} 
      />
      <label htmlFor={id} className="p-checkbox-label">{label}</label>
      {touched && error && <span className="form-error form-error-checkbox">{error}</span>}
    </div>
  );
}

// no worky
export const CheckboxGroup = ({ 
  input: { name, value, onChange, ...inputProps }, 
  id,
  containerClass="p-col-6",
  options=[],
  meta: { touched, error }, 
  ...custom 
}) => {
  console.log(value, inputProps)
  return (
    <div className="form-group p-grid">
      {options.map(option => {
        const idProp = `${id}_${option.value}`;
        const nameProp = `${name}.${option.value}`;
        return (
          <div key={option.value} className={containerClass}>
            <PrimeCheckbox
              id={idProp}
              checked={value ? value.includes(option.value) : false}
              className={touched && error ? "p-error" : null}
              name={nameProp}
              value={option.value}
              onChange={e => {
                console.log(e.target.name, e.target.value);
                onChange(e);
              }}
              {...inputProps}
              {...custom} 
            />
            <label htmlFor={idProp} className="p-checkbox-label">{option.label}</label>
          </div>
        );
      })}
      {touched && error && <div className="form-error p-col-12">{error}</div>}
    </div>
  );
}

export const Dropdown = ({ input, id, label, help, options=[], meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <PrimeDropdown
        id={id}
        className={touched && error ? "p-error" : null}
        options={options}
        autoWidth={false}
        {...input}
        {...custom} 
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const FileUpload = ({ 
  input: {
    name,
    onBlur,
    onChange,
    onDragStart,
    onDrop,
    onFocus,
  }, 
  id, 
  label,
  help,
  mode="basic",
  meta: { touched, error },
  ...custom 
}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      {/* <PrimeFileUpload
        id={id}
        className={touched && error ? "p-error" : null}
        mode={mode}
        {...input}
        {...custom} 
      /> */}
      <input
        type="file"
        id={id}
        className={touched && error ? "p-error" : null}
        mode={mode}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onFocus={onFocus}
        {...custom} 
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const InputText = ({ input, id, label, help, type="text", size=30, meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <PrimeInputText
        id={id}
        type={type}
        size={size}
        className={touched && error ? "p-error" : null}
        {...input}
        {...custom} 
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const InputTextFloat = ({ input, id, label, help, type="text", size=30, meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group float-label">
      <span className="p-float-label">
        <PrimeInputText
          id={id}
          type={type}
          size={size}
          className={touched && error ? "p-error" : null}
          {...input}
          {...custom} 
        />
        <label htmlFor={id}>{label}</label>
      </span>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const InputTextarea = ({ input, id, label, help, type="text", autoResize=true, meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <PrimeInputTextArea
        id={id}
        type={type}
        className={touched && error ? "p-error" : null}
        autoResize={autoResize}
        {...input}
        {...custom} 
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const MultiSelect = ({ input: { value, ...inputProps }, id, label, help, options=[], meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <PrimeMultiSelect
        id={id}
        className={touched && error ? "p-error" : null}
        options={options}
        autoWidth={false}
        value={value ? value : []} // passed in as empty string initially
        {...inputProps}
        {...custom} 
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const RadioButton = ({ input, id, label, help, meta: { touched, error }, ...custom }) => {
  return (
    <div className="form-group">
      <PrimeRadioButton
        id={id}
        checked={input.value===true}
        className={touched && error ? "p-error" : null}
        {...input}
        {...custom} 
        />
      <label htmlFor={id} className="p-checkbox-label">{label}</label>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}

export const RadioButtonGroup = ({ input: { onChange, name, value }, id = '', label, options, meta: { touched, error }, ...custom }) => {
  // console.log(value, name, onChange);
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="radionButtonGroupOptions">
        {options.map((option,i) => (
          <div key={id+'-'+option.value}>
            <PrimeRadioButton
              id={id+'-'+i}
              checked={value===option.value}
              className={touched && error ? "p-error" : null}
              // {...input}
              name={name}
              onChange={e => {
                // console.log({...e})
                onChange(e);
              }}
              value={option.value}
              {...custom} 
              />
            <label htmlFor={id+'-'+i} className="p-checkbox-label">{option.label}</label>
          </div>
        ))}
      </div>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
}