import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import PhotoPermission from './PhotoPermission';

class PhotoPermissionDialog extends Component {

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    return (
      <Dialog 
        className="PhotoPermissionDialog"
        visible={open}
        onHide={() => toggle(false)}
        header="Photo Permission"
      >
        <PhotoPermission />
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PhotoPermissionDialog);