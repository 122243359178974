import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Dropdown, FileUpload, InputText } from 'components/FormFields';
import phoneTypeList from 'helpers/Filter/phoneTypes';
import statesList from 'helpers/Filter/states';
import { phoneMask } from 'helpers/Masks';

class Profile extends Component {

  render() {

    const { formData, formLoaded, data } = this.props;
    const { links } = data;

    let contactImage = null;
    if (formLoaded && formData.contact_image && formData.contact_image[0]) {
      contactImage = URL.createObjectURL(formData.contact_image[0]);
    } else if (links && links.image) {
      contactImage = links.image;
    }

    return (
      <div className="MyProfile">
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="First Name *"
              name="contact[first_name]" 
              // disabled={isFetching}
              component={InputText}
            />
            <Field
              label="Last Name *"
              name="contact[last_name]" 
              // disabled={isFetching}
              component={InputText}
            />
            <Field
              label="Email *"
              name="contact[email]" 
              // disabled={isFetching}
              type="email"
              component={InputText}
            />
            <Field
              label="Phone *"
              name="contact[phone]" 
              // disabled={isFetching}
              component={InputText}
              {...phoneMask}
            />
            <Field
              label="Phone Type *"
              id="phone_type_id" 
              name="contact[phone_type_id]" 
              // disabled={isFetching}
              component={Dropdown}
              options={phoneTypeList}
            />
          </div>
          <div className="p-col p-lg-6">
            <div className="p-grid">
              <div className="p-col-6 textCenter">
                {contactImage ?
                  <img src={contactImage} className="contact-image" alt="Contact profile" />
                  : '-- No Image --'
                }
              </div>
              <div className="p-col-6" style={{ overflow: 'hidden' }}>
                <Field
                  label="Upload New Photo"
                  name="contact_image" 
                  // disabled={isFetching}
                  component={FileUpload}
                  accept="image/*"
                />
              </div>
            </div>
            <Field
              label="Street Address *"
              name="contact[street_address]" 
              // disabled={isFetching}
              component={InputText}
            />
            <Field
              label="Street Address 2"
              name="contact[supplemental_address_1]" 
              // disabled={isFetching}
              component={InputText}
            />
            <Field
              label="City *"
              name="contact[city]" 
              // disabled={isFetching}
              component={InputText}
            />
            <div className="p-grid">
              <div className="p-col p-lg-6">
                <Field
                  label="State *"
                  name="contact[state_province_id]" 
                  // disabled={isFetching}
                  component={Dropdown}
                  options={statesList}
                />
              </div>
              <div className="p-col p-lg-6">
                <Field
                  label="Zip *"
                  name="contact[postal_code]" 
                  // disabled={isFetching}
                  component={InputText}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {

  const { auth: { siteURL, testMode }, single } = state;

  return {
    ...single[singleName],
    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Profile);