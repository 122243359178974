import constants from '../constants';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import {
  // updateListData,
  updateCurrentListState,
} from './list';
import { addMessage } from './messages';

export const eventRegister = (save, event) => dispatch => {

  const { rsvp_status, rsvp_status_orig } = event;

  let participant_id = null, status_id = null;

  switch (rsvp_status) {
    /**
     * New Registrations
     */
    case 'dropin':
      status_id = 16; // confirmed
      break;
    case 'rsvp':
      status_id = 8; // awaiting approval
      break;
    case 'waitlist':
      status_id = 7; // waitlisted
      break;
    /**
     * Reregistrations after canceled
     */
    case 'cancelled':
      participant_id = event['api.Participant.get'].values[0].id;
      switch (rsvp_status_orig) {
        case 'dropin':
          status_id = 16; // confirmed
          break;
        case 'rsvp':
          status_id = 8; // awaiting approval
          break;
        case 'waitlist':
          status_id = 7; // waitlisted
          break;
        default:
          return;
      }
      break;
    /**
     * Registration cancels
     */
    case 'registered':
    case 'waitlisted':
    case 'pendingapproval':
    case 'pendingattended':
      participant_id = event['api.Participant.get'].values[0].id;
      status_id = 24; // pending cancellation
      break;
    /**
     * Unavailable statuses
     */
    case 'past':
    case 'unavailable':
    case 'hide':
    default:
      // dispatch(addMessage('Unavailable for update', 'error'));
      return;
  }

  dispatch({
    type: constants.SAVE_START, 
    value: { save },
  });

  const formData  = new FormData();
  formData.append('event_id', event.id);
  formData.append('status_id', status_id);
  if (participant_id!==null) 
    formData.append('id', participant_id);

  const url = handleCiviURLBuild('Registration', 'create', {
    sequential: 1,
  });

  fetch(url,{
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }, 
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    if (json.is_error)
      throw new Error(json.error_message);
    /**
     * Regrab list by invalidating list state
     */
    dispatch(updateCurrentListState('rsvp', ''));
    /**
     * Update list in place
     */
    // dispatch(updateListData(
    //   'rsvp',
    //   json.values,
    //   (record, update) => {

    //     // console.log(record, update)
        
    //     // not the right event, no update
    //     if (record.id !== update.event_id) {
    //       // console.log('not same id')
    //       return record;
    //     }
          
    //     const idx = record['api.Participant.get'].values.findIndex(v => v.id===update.id);    
    //     // new registration
    //     if (idx === -1) {
    //       // console.log('new registration')
    //       record['api.Participant.get'].values.push(update);
    //       record['api.Participant.get'].count++;
    //       return record;
    //     }

    //     // registration update
    //     // console.log('registration update')
    //     record['api.Participant.get'].values[idx] = Object.assign({}, record['api.Participant.get'].values[idx], update);
    //     return record;
    //   }
    // ));
  })
  .then(() => dispatch({ type: constants.SAVE_END, value: { save } }))
  .catch(e => {
    dispatch({ type: constants.SAVE_END, value: { save } });
    dispatch(addMessage(e.message, 'error'))
  });
}