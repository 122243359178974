import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { clearMessages } from 'store/actions/messages';
import { Growl } from 'primereact/growl';

const growlRef = React.createRef();

const alreadyShown = []; // prevent weird close animation

class Messages extends Component {

  componentDidMount() {
    this.showMessages();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.showMessages();    
  }

  showMessages = () => {
    if (this.props.messages.length>0) {
      const messages = []
      this.props.messages.forEach(message => {
        if (alreadyShown.includes(message.uuid))
          return;
        message.life = 7000;
        messages.push(message);
        alreadyShown.push(message.uuid);
      });
      if (messages.length>0)
        growlRef.current.show(messages);
    }
  }

  render() {

    const { handleClearMessage } = this.props;

    return (
      <Growl 
        ref={growlRef}
        onRemove={e => handleClearMessage(e.uuid)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn } = state.auth;
  const messages = state.messages.items;
  return {
    loggedIn,
    messages,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleClearMessage: (id) => dispatch(clearMessages(id)),
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Messages);