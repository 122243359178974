import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { Button } from 'primereact/button';
// import { InputText } from 'primereact/inputtext';
import { TabMenu } from 'primereact/tabmenu';
import menuitems from './TitleBar/menuitems';
import './TitleBar/styles.css';

class TitleBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItemIdx: null,
    };
  }

  componentDidMount() {
    this.setActiveItem();
  }

  componentDidUpdate() {
    this.setActiveItem();
  }

  setActiveItem = () => {
    
    const { pathname } = this.props.location;
    const activeItemIdx = menuitems.findIndex(v => v.to === pathname)
    if (activeItemIdx !== this.state.activeItemIdx) {
      this.setState({ activeItemIdx });
    }
  }

  render() {

    const { contact, handleLogout, history, location, mobileMode } = this.props;
    const { activeItemIdx } = this.state;
    // console.log(activeItemIdx);

    const { image_URL } = contact;
    let contactImage = null;
    if (image_URL) {
      const elem = document.createElement('textarea');
      elem.innerHTML = image_URL;
      contactImage = elem.value;
    }

    return (
      <div className="TitleBar">
        <div className="contact-info">
          <div className="p-grid p-align-center">
            {contactImage &&
              <div className="p-col-fixed">
                <img src={contactImage} className="contact-image" alt="Contact profile" />
              </div>
            }
            <div className="p-col">
              <h2>Welcome {contact.display_name}</h2>
            </div>
            {!mobileMode && 
              <div className="p-col textRight">
                <Button label="Sign Out" onClick={() => handleLogout()} />
              </div>
            }
          </div>
        </div>
        {!mobileMode && 
          <TabMenu
            model={menuitems}
            activeItem={activeItemIdx>-1 ? menuitems[activeItemIdx] : -1}
            onTabChange={e => location.pathname !== e.value.to && history.push(e.value.to)} 
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { contact, siteURL, testMode } = state.auth;
  const { mobileMode } = state.display;
  return {
    contact,
    mobileMode,
    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);