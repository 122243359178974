const photoPermissions = [
  {
    label: 'No',
    value: '2',
  },
  {
    label: 'Yes',
    value: '1',
  },
];

export default photoPermissions;