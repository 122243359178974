import constants from '../constants';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { addMessage } from './messages';

export const loadStatusMap = () => dispatch => {
  // grab any settings we need
  let url = handleCiviURLBuild('Registration', 'getsetup', {
    options: {
      limit: 0,
    }
  });
  fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }, 
  })
  .then(response => response.json())
  .then(json => {
    if (json.is_error)
      throw new Error(json.error_message);
    dispatch({
      type: constants.STATUS_MAP,
      value: json.participant_status_types,
    });
  })
  .catch(e => {
    // console.log(e);
    dispatch(addMessage(e.message, 'error'))
  });
}
