import React, { Component } from 'react';
import TOSDialog from './Footer/TOSDialog';
import PrivacyPolicyDialog from './Footer/PrivacyPolicyDialog';
import FAQDialog from './Footer/FAQDialog';
import './Footer/styles.css';

class Footer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tosOpen: false,
      privacyPolicyOpen: false,
      faqOpen: false,
    };
  }

  toggleTosOpen = (state=null) => this.setState({ tosOpen: state===null ? !this.state.tosOpen : state });
  togglePrivacyPolicyOpen = (state=null) => this.setState({ privacyPolicyOpen: state===null ? !this.state.privacyPolicyOpen : state });
  toggleFaqOpen = (state=null) => this.setState({ faqOpen: state===null ? !this.state.faqOpen : state });

  render() {

    const { tosOpen, privacyPolicyOpen, faqOpen } = this.state;

    return (
      <div className="Footer">
        <div className="p-grid p-justify-center">
          <div className="p-col-fixed">
            <span className="fake-link" onClick={() => this.toggleTosOpen(true)}>Terms Of Service</span>
          </div>
          <div className="p-col-fixed">
            <span className="fake-link" onClick={() => this.togglePrivacyPolicyOpen(true)}>Privacy Policy</span>
          </div>
          <div className="p-col-fixed">
            <span className="fake-link" onClick={() => this.toggleFaqOpen(true)}>FAQ</span>
          </div>
        </div>
        <TOSDialog open={tosOpen} toggle={this.toggleTosOpen} />
        <PrivacyPolicyDialog open={privacyPolicyOpen} toggle={this.togglePrivacyPolicyOpen} />
        <FAQDialog open={faqOpen} toggle={this.toggleFaqOpen} />
      </div>
    );
  }
}

export default Footer;