import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Calendar, Dropdown, InputText, MultiSelect } from 'components/FormFields';
import booleanList from 'helpers/Filter/boolean';
import genderList from 'helpers/Filter/gender';
import racesList from 'helpers/Filter/races';
import religionsList from 'helpers/Filter/religions';
import sexualOrientationsList from 'helpers/Filter/sexualOrientations';
import * as moment from 'moment-timezone';

class Demographics extends Component {
  
  render() {

    return (
      <div className="Demographics">
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="Date of Birth *"
              name="contact[birth_date]" 
              // disabled={isFetching}
              showTime={false}
              component={Calendar}
              touchUI={true}
              readOnlyInput={true}
              showIcon={true}
              monthNavigator={true}
              yearNavigator={true}
              yearRange={moment().add(-120, 'year').format('YYYY') + ":" + moment().add(-50, 'year').format('YYYY')}
              // minDate={moment().tz('America/Los_Angeles').add(1, 'day').startOf('day').toDate()}
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label="Are you disabled? *"
              id="custom_1213" 
              name="contact[custom_1213]" 
              // disabled={isFetching}
              component={Dropdown}
              options={booleanList}
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="Veteran *"
              id="custom_115" 
              name="contact[custom_115]" 
              // disabled={isFetching}
              component={Dropdown}
              options={booleanList}
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label="Gender *"
              id="gender_id" 
              name="contact[gender_id]" 
              // disabled={isFetching}
              component={Dropdown}
              options={genderList}
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="Are you transgender? *"
              id="custom_1215" 
              name="contact[custom_1215]" 
              // disabled={isFetching}
              component={Dropdown}
              options={booleanList}
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label="Sexual Orientation *"
              id="custom_113" 
              name="contact[custom_113]" 
              // disabled={isFetching}
              component={Dropdown}
              options={sexualOrientationsList}
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="Race *"
              id="custom_1212" 
              name="contact[custom_1212]" 
              // disabled={isFetching}
              component={MultiSelect}
              options={racesList}
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label="Are you currently homeless? *"
              id="custom_1214" 
              name="contact[custom_1214]" 
              // disabled={isFetching}
              component={Dropdown}
              options={booleanList}
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <Field
              label="How many people live in your household?"
              name="contact[custom_1216]" 
              // disabled={isFetching}
              component={InputText}
              keyfilter="num"
            />
          </div>
          <div className="p-col p-lg-6">
            <Field
              label="What is your estimated Annual Income?"
              name="contact[custom_1217]" 
              // disabled={isFetching}
              component={InputText}
              keyfilter="num"
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-lg-12">
            <Field
              label="Do you or have you identified with the following religions/spiritual practices?"
              id="custom_1451" 
              name="contact[custom_1451]" 
              // disabled={isFetching}
              component={MultiSelect}
              options={religionsList}
            />
          </div>
        </div>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {
  const single = state.single[singleName];
  return {
    ...single,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Demographics);