import React, { Component } from 'react';
import { connect } from 'react-redux';

import sign_in from 'assets/images/faq/1_Sign_In.jpg';
import recover_my_account from 'assets/images/faq/2_Recover My Account.jpg';
import sign_up from 'assets/images/faq/3_Sign_Up.jpg';
import my_account from 'assets/images/faq/4_My Account.jpg';
import my_rsvps from 'assets/images/faq/5_My RSVPs.jpg';
import event_information_with_ada from 'assets/images/faq/6_Event Information with ADA.jpg';
import days_of_the_week from 'assets/images/faq/7_Days of the Week.jpg';
import days_of_the_week_2 from 'assets/images/faq/8_Days of the Week 2.jpg';
import search_filters from 'assets/images/faq/9_Search Filters.jpg';
import location_filter from 'assets/images/faq/10_Location Filter.jpg';
import rsvp_status_filter from 'assets/images/faq/11_RSVP Status Filter.jpg';
import event_information from 'assets/images/faq/12_Event Information.jpg';
import event_location from 'assets/images/faq/13_Event Location.jpg';
import case_management from 'assets/images/faq/14_Case Management.jpg';
import cancel_appointment from 'assets/images/faq/15_Cancel Appointment.jpg';
import account_my_profile from 'assets/images/faq/16_Account_My Profile.jpg';
import account_my_preferences from 'assets/images/faq/17_Account_My Preferences.jpg';
import account_demographic_info from 'assets/images/faq/18_Account_Demographic Info.jpg';
import my_account_change_password from 'assets/images/faq/19_My Account_Change Password.jpg';
import sign_out from 'assets/images/faq/20_Sign Out.jpg';
import are_you_still_here from 'assets/images/faq/21_Are you still here.jpg';

class FAQ extends Component {

  render() {
    
    return (
      <div className="FAQ">
        <p><strong>Q:</strong> How do I login to the Portal?</p>
        <p><strong>A:</strong> You can login to the Portal by typing in your Username and Password, that"s been provided to you by the Center, onto the respective input boxes on the Portal"s login page. You can then click on the "Sign In" button to login. You can check the "Show Password" checkbox above the "Sign In" button to see whether the password you inserted is correct or not.</p>
        <p><strong>Note:</strong> Please be advised that when you access the Portal from a public facility, like the Cyber Center or a library, be careful not to expose your password to anyone.</p>
        <p><img src={sign_in} alt="Sign in" /></p>
        <p><strong>Q:</strong> What if I forget my "Username" or "Password" or both?</p>
        <p><strong>A:</strong> If you forgot your username or both your username and password, please contact a Senior Services staff to give you your username but if you know your username but forgot your password, please click on the "Forgot Username / Password" link that"s shown on the login page. This will prompt you to insert your username or email. Once you click on "Recover Account", the system will send you an account recovery email with your new password. You can then click on "Back to Sign In" to go back to the login page.</p>
        <p><img src={recover_my_account} alt="Recover my account" /></p>
        <p><strong>Q:</strong> I"m new to the center. How can I create my account on the Portal?</p>
        <p><strong>A:</strong> You can create your account by clicking on the "Register" button on the login page of the Portal. You"ll then be required to provide your profile information. You can also upload your photo by clicking on "Browse" (which is highlighted on the picture below). Once you provide your profile information, please click on "Create Account".</p>
        <p><img src={sign_up} alt="Register" /></p>
        <p>In the case that you forgot to fill out the mandatory fields, the system will show you all the required fields in red so that you can provide the information.</p>
        <p><strong>Note:</strong> Please check one or more of the items under "My Preferences" if you either wish to be contacted by or more of the communication methods or not. </p>
        <p><img src={my_account} alt="My account" /></p>
        <p><strong>Q:</strong> How do I RSVP for an event?</p>
        <p><strong>A:</strong> Once you login to the Portal, you will see a "My RSVPs" tab on the top of the screen. Below this, you will see dates starting from the current day. The current day is highlighted in yellow. Just below the "Search" filters, you will then see a table with a list of Event Date and Time, Event Name, Location and RSVP Status just below that and this table shows all the events for all the dates shown on top of the screen. If you would like to RSVP for an event or events on a specific date, please click on that date and this will populate the table with those events for that date. You can then click the "Needs RSVP" button that"s shown under the "RSVP Status" column of the table.</p>
        <p><img src={my_rsvps} alt="My RSVPs" /></p>
        <p>You will see a pop-up window with all the details for the event and you can click on "Click here to RSVP" button that"s shown on the bottom of the window to confirm your request. Here, you can also submit any "Accommodation(s) Request" you might have for that event.</p>
        <p><img src={event_information_with_ada} alt="Event Information with ADA" /></p>
        <p><strong>Q:</strong> Can I request special accommodations for event/s?</p>
        <p><strong>A:</strong> Yes you can request for accommodations for events by clicking on the "Do you need special Accommodations?" checkbox as shown above. In general, ADA requests must be made 10 days in advance and once you submit your request, it will be reviewed by the Center"s staff. You will then receive a confirmation once your request is approved.</p>
        <p><strong>Q:</strong> Can I RSVP for an event happening in the coming weeks?</p>
        <p><strong>A:</strong> Yes. You can RSVP for events that are happening in the coming weeks within the current month. Please click on the &lt;and&gt;buttons to view more dates.</p>
        <p><strong>Q:</strong> How do I search for specific event/s?</p>
        <p><strong>A:</strong> If you know the exact date that you would like to do your search on, please choose the day from the available list and it will be highlighted in a light grey color as shown below.</p>
        <p><img src={days_of_the_week} alt="Specific day of the week" /></p>
        <p>Otherwise, if you want to search for an event for all the days shown on the list, please don"t select any specific date and this will return the search result for all the dates.</p>
        <p><img src={days_of_the_week_2} alt="All days of the week" /></p>
        <p>Once you make your decision about the date/s, there are three ways that you can search for an event or events.</p>
        <ol start="1">
          <li>
            <p>Using the "Search" text box - Here you can type-in the full or part of the event name that you"re looking for and the list will be populated on the table. If the event is not listed, you will see the message "No records found".</p>
            <p><strong>Note:</strong> You can delete what you wrote or click on "Clear Filters" to remove your search criteria.</p>
            <p><img src={search_filters} alt="Search Filters" /></p>
          </li>
          <li>
            <p>Using the "Location" dropdown menu - You can search events by selecting one or more of the available locations from the dropdown menu. This will show all the events happening on the location/s chosen.</p>
            <p><strong>Note:</strong> You can deselect the location/s from the dropdown menu or click on "Clear Filters" to remove your search criteria.</p>
            <p><img src={location_filter} alt="Location Filter" /></p>
          </li>
          <li>
            <p>Using "RSVP Status" - This dropdown menu will enable you to select one or more of your RSVP statuses and it will populate your search result on the table.</p>
            <p><strong>Note:</strong> You can deselect the status/es from the dropdown menu or click on "Clear Filters" to remove your search criteria.</p>
            <p><img src={rsvp_status_filter} alt="RSVP Status Filter" /></p>
          </li>
        </ol>
        <p><strong>Q:</strong> How can I view more information about the event?</p>
        <p><strong>A:</strong> When you click on the "Event Name", you will be able to see more information about the event like the date and time, location, room number, RSVP status and whether the event is a drop-in allowed event or not. You can also view the same information when click on the status of the event.</p>
        <p><img src={event_information} alt="Event information" /></p>
        <p><strong>Q:</strong> What if I don"t know the address of the location where the event is happening?</p>
        <p><strong>A:</strong> Please click on the location name and it will show you a map of the event as shown below.</p>
        <p><img src={event_location} alt="Event Location" /></p>
        <p><strong>Q:</strong> How do I cancel my RSVP?</p>
        <p><strong>A:</strong> There are two ways to cancel your "Pending", "Waitlisted" or "Confirmed" RSVPs.</p>
        <ol start="1">
          <li>You can click on the "Event Name" and then your event window will appear. On the bottom of this window, you can click on the "Cancel RSVP" button. This will trigger the "RSVP Cancellation" window asking you to confirm your decision. If you click "Yes", your pending or confirmed RSVP will be cancelled but if you click on "No", and then "Close" on the event window, the RSVP will not be cancelled.</li>
          <li>The second way to cancel a pending, waitlisted or confirmed RSVP is by clicking on the "RSVP Status". You can then click on the "Cancel RSVP" button, which in turn shows you the cancellation confirmation window. If you click on "Yes", your RSVP will be cancelled. Otherwise, the status of your RSVP will remain the same. </li>
        </ol>
        <p><strong>Q:</strong> Once I cancel my RSVP, can I RSVP again?</p>
        <p><strong>A:</strong> Yes you can RSVP to an event that you"ve already opted out of unless the event is full or the event date has already passed.</p>
        <p><strong>Q:</strong> How can I schedule a Case Management Appointment?</p>
        <p><strong>A:</strong> You can schedule a case management appointment 3 days in advance by clicking on the "Case Management" tab on the Portal. Afterwards you can choose the appointment date, time, location, reason for request and provide any comments that you may have then click on "Submit Request". Your request will be shown on the right side of the window with a status "Requested" highlighted as shown below.</p>
        <p><img src={case_management} alt="Case management" /></p>
        <p><strong>Q:</strong> Can I cancel my case management appointment request?</p>
        <p><strong>A:</strong> Yes you can cancel your case management appointment request by clicking on "Need to cancel appointment?" as shown below. You will be asked to confirm whether you"d like to cancel the appointment or not, if you click on "Yes", your appointment will be cancelled.</p>
        <p><strong>Note:</strong> Please notify the Center 48 hours before your appointment to cancel your case management request.</p>
        <p><img src={cancel_appointment} alt="Cancel appointment" /></p>
        <p><strong>Q:</strong> Is there a way for me to update my personal information on the Portal?</p>
        <p><strong>A:</strong> You can update your basic personal information such as your first and last names, your email address, phone number and address. Moreover, you can upload your photo by clicking on the "Choose File" button under the "Account" tab.</p>
        <p><img src={account_my_profile} alt="Account and Profile" /></p>
        <p>You can also set your privacy preferences and preferred communication methods by choosing one or more options under the "My Preferences" portion of your account.</p>
        <p><img src={account_my_preferences} alt="Account Preferences" /></p>
        <p>The third portion of your account is your demographic information and you can update any of the fields on this portion as well. Please make sure to provide information for all the required fields before updating your information.</p>
        <p><img src={account_demographic_info} alt="Account Demographics" /></p>
        <p><strong>Q:</strong> Can I change my password?</p>
        <p><strong>A:</strong> You can change your password by going to "My Account" under the "Account" tab. Your password must be at least 8 characters long and should include a number and both upper and lower case letters. If your new password doesn"t satisfy the above mentioned conditions, the Portal will notify you that the password you provided "Does not match requirements&rdquo;.</p>
        <p>Once you provide your new password and then confirmed it, please click on "Update". If both passwords match, your new password will be saved on the Portal. You can use your new password when you login both to the Portal and the Kiosk. But the passwords that you inserted do not match, an error message will appear that says "Passwords must match". Please re-enter your new password on both input boxes and then click "Update".</p>
        <p><img src={my_account_change_password} alt="Change Password" /></p>
        <p><strong>Q:</strong> How do I log out of the Portal?</p>
        <p><strong>A:</strong> Please click on the "Sign Out" button on the top right corner of the Portal to log off.</p>
        <p><img src={sign_out} alt="Sign out" /></p>
        <p>To secure your personal information, the Portal will log you out automatically after 15 minutes of inactivity after asking for your confirmation whether you"re still online or not.</p>
        <p><img src={are_you_still_here} alt="Are you still here?" /></p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FAQ);