import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class NotFound extends Component {

  render() {

    const { mobileMode } = this.props;

    return (
      <div className="NotFound">
        <div className="p-grid p-justify-center">
          <div className={mobileMode ? "p-col-12" : "p-col-6"}>
            <h3>Page does not exists. Would you like to go <Link to="/">Home</Link></h3>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
