const states = [
  {
    "label": "Alabama",
    "value": "1000"
  },
  {
    "label": "Alaska",
    "value": "1001"
  },
  {
    "label": "American Samoa",
    "value": "1052"
  },
  {
    "label": "Arizona",
    "value": "1002"
  },
  {
    "label": "Arkansas",
    "value": "1003"
  },
  {
    "label": "Armed Forces Americas",
    "value": "1060"
  },
  {
    "label": "Armed Forces Europe",
    "value": "1059"
  },
  {
    "label": "Armed Forces Pacific",
    "value": "1061"
  },
  {
    "label": "California",
    "value": "1004"
  },
  {
    "label": "Colorado",
    "value": "1005"
  },
  {
    "label": "Connecticut",
    "value": "1006"
  },
  {
    "label": "Delaware",
    "value": "1007"
  },
  {
    "label": "District of Columbia",
    "value": "1050"
  },
  {
    "label": "Florida",
    "value": "1008"
  },
  {
    "label": "Georgia",
    "value": "1009"
  },
  {
    "label": "Guam",
    "value": "1053"
  },
  {
    "label": "Hawaii",
    "value": "1010"
  },
  {
    "label": "Idaho",
    "value": "1011"
  },
  {
    "label": "Illinois",
    "value": "1012"
  },
  {
    "label": "Indiana",
    "value": "1013"
  },
  {
    "label": "Iowa",
    "value": "1014"
  },
  {
    "label": "Kansas",
    "value": "1015"
  },
  {
    "label": "Kentucky",
    "value": "1016"
  },
  {
    "label": "Louisiana",
    "value": "1017"
  },
  {
    "label": "Maine",
    "value": "1018"
  },
  {
    "label": "Maryland",
    "value": "1019"
  },
  {
    "label": "Massachusetts",
    "value": "1020"
  },
  {
    "label": "Michigan",
    "value": "1021"
  },
  {
    "label": "Minnesota",
    "value": "1022"
  },
  {
    "label": "Mississippi",
    "value": "1023"
  },
  {
    "label": "Missouri",
    "value": "1024"
  },
  {
    "label": "Montana",
    "value": "1025"
  },
  {
    "label": "Nebraska",
    "value": "1026"
  },
  {
    "label": "Nevada",
    "value": "1027"
  },
  {
    "label": "New Hampshire",
    "value": "1028"
  },
  {
    "label": "New Jersey",
    "value": "1029"
  },
  {
    "label": "New Mexico",
    "value": "1030"
  },
  {
    "label": "New York",
    "value": "1031"
  },
  {
    "label": "North Carolina",
    "value": "1032"
  },
  {
    "label": "North Dakota",
    "value": "1033"
  },
  {
    "label": "Northern Mariana Islands",
    "value": "1055"
  },
  {
    "label": "Ohio",
    "value": "1034"
  },
  {
    "label": "Oklahoma",
    "value": "1035"
  },
  {
    "label": "Oregon",
    "value": "1036"
  },
  {
    "label": "Pennsylvania",
    "value": "1037"
  },
  {
    "label": "Puerto Rico",
    "value": "1056"
  },
  {
    "label": "Rhode Island",
    "value": "1038"
  },
  {
    "label": "South Carolina",
    "value": "1039"
  },
  {
    "label": "South Dakota",
    "value": "1040"
  },
  {
    "label": "Tennessee",
    "value": "1041"
  },
  {
    "label": "Texas",
    "value": "1042"
  },
  {
    "label": "United States Minor Outlying Islands",
    "value": "1058"
  },
  {
    "label": "Utah",
    "value": "1043"
  },
  {
    "label": "Vermont",
    "value": "1044"
  },
  {
    "label": "Virgin Islands",
    "value": "1057"
  },
  {
    "label": "Virginia",
    "value": "1045"
  },
  {
    "label": "Washington",
    "value": "1046"
  },
  {
    "label": "West Virginia",
    "value": "1047"
  },
  {
    "label": "Wisconsin",
    "value": "1048"
  },
  {
    "label": "Wyoming",
    "value": "1049"
  }
];

export default states;