import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Dialog } from 'primereact/dialog';
import MapComponent from './EventListMap/MapComponent';

class EventListMap extends Component {

  constructor(props){
    super(props);

    this.state = {
      zoom: 12,
      location: null,
    };
  }

  /**
   * window.google.maps is not available until the map loads
   * we need this for geocoding, pass a call back to receive 
   * that and update our location in state
   */

  componentDidMount() {
    const { location } = this.props;
    this.setState({ location });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    const oldLocation = prevProps.location;
    const { location } = this.props;
    if (JSON.stringify(oldLocation) !== JSON.stringify(location)) {
      this.setState({ location });
    }
  }

  handleGeoCodeAddress = (google, marker) => {

    const { handleMessage } = this.props;
    const location = {...marker};

    if (!google || !google.maps || !location.address || location.address.length===0) {
      handleMessage('Could not locate address', 'error');
      return;
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode( { 'address': location.address}, (results, status) => {
      if (status === 'OK') {
          location.position = results[0].geometry.location.toJSON();
          this.setState({ location });
          return;
      }

      handleMessage('Could not locate address', 'error');
    });
  }

  render() {

    const { setLocation } = this.props;
    const { location, zoom } = this.state;

    if (!location) return null;

    const center = location.position ? location.position : { lat: 34.100552, lng: -118.332732 };

    return (
      <Dialog 
        className="Map"
        visible={true}
        maximizable={true}
        onHide={() => setLocation(null)}
        header={location.label + ' - ' + location.address}
      >
        <MapComponent
          // googleMapURL="https://www.google.com/maps/embed/v1/place?q=1625+N.+Schrader+Blvd.+%0D%0ALos+Angeles%2C+CA+90028-6213&key=AIzaSyDWvMJSOuVnUXpq3BNWXu5Xf17VoCzPtqM"
          googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3&key="+process.env.REACT_APP_GOOGLE_API_KEY}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `600px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          marker={location}
          center={center}
          zoom={zoom}
          handleCenterChange={newCenter => this.setState({ center: newCenter})}
          handleZoomChange={newZoom => this.setState({ zoom: newZoom })}
          geoCodeAddress={this.handleGeoCodeAddress}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EventListMap);