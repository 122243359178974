const rooms = [
  {  
    label: 'Room 135',
    value: '1',
  },
  {  
    label: 'Room 136',
    value: '2',
  },
  {  
    label: 'Room 137',
    value: '3',
  },
  {  
    label: 'Room 139',
    value: '4',
  },
  {  
    label: 'Room 209',
    value: '5',
  },
  {  
    label: 'The Gallery',
    value: '6',
  },
  {  
    label: 'DV Theater',
    value: '7',
  },
  {  
    label: 'Renberg Theater',
    value: '8',
  },
  {  
    label: 'Courtyard',
    value: '9',
  },
  {  
    label: 'Cyber Center',
    value: '10',
  },
  {  
    label: 'Community Room',
    value: '11',
  },
  {  
    label: 'Media Room',
    value: '12',
  },
  {  
    label: 'Library',
    value: '13',
  },
  {  
    label: 'Art Room',
    value: '14',
  },
  {  
    label: 'Fitness Room',
    value: '15',
  },
  {  
    label: 'Community Garden',
    value: '16',
  },
  {  
    label: 'Front Patio',
    value: '17',
  },
  {  
    label: 'Pool',
    value: '18',
  },
  {  
    label: '1017 Oscar Wilde',
    value: '19',
  },
  {  
    label: '1019 Virginia Wolf',
    value: '20',
  },
  {  
    label: '249 James Baldwin',
    value: '21',
  },
  {  
    label: '250 Harvey Milk',
    value: '22',
  },
  {  
    label: '251 Marsha P. Johnson',
    value: '23',
  },
];

export default rooms;