import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { login } from 'store/actions/auth';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { Button } from 'primereact/button';
import cx from 'classnames';
// import * as moment from 'moment-timezone';
import './Register/styles.css';
// import UsernamePassword from './Account/UsernamePassword';
import Demographics from './Account/Demographics';
import WeHo from './Account/WeHo';
import EmergencyContact from './Account/EmergencyContact';
import Preferences from './Account/Preferences';
import Profile from './Account/Profile';
import Releases from './Account/Releases';
import * as moment from 'moment-timezone';

class Register extends Component {

  submitForm = values => {

    const { handleMessage, /*handleLogin,*/ history } = this.props;
    
    const auth = {...values.auth};
    const contact = {...values.contact};
    const weho = {...values.weho};
    const { contact_image } = values;

    /**
     * Validate
     */

    const errors = {};
    const authErrors = {};
    const contactErrors = {};
    const wehoErrors = {};

    // Account info

    // if (!auth.username) 
    //   authErrors.username = 'Required';

    // if (!auth.password) 
    //   authErrors.password = 'Required';
    // else if (
    //   auth.password.length<8 ||
    //   !auth.password.match(/[a-z]+/) ||
    //   !auth.password.match(/[A-Z]+/) ||
    //   !auth.password.match(/[0-9]+/)
    // )
    //   authErrors.password = 'Does not match requirements';

    // if (!auth.password_confirmation) 
    //   authErrors.password_confirmation = 'Required';

    // if (auth.password!==auth.password_confirmation)
    //   authErrors.password_confirmation = 'Passwords must match';

    // Profile

    if (!contact.first_name)
      contactErrors.first_name = 'Required';
  
    if (!contact.last_name)
      contactErrors.last_name = 'Required';
  
    if (!contact.street_address)
      contactErrors.street_address = 'Required';
  
    if (!contact.city)
      contactErrors.city = 'Required';
    
    if (!contact.state_province_id)
      contactErrors.state_province_id = 'Required';
    
    if (!contact.postal_code)
      contactErrors.postal_code = 'Required';
  
    if (!contact.phone)
      contactErrors.phone = 'Required';
    else if (!contact.phone.match(/^\(\d{3}\)\s\d{3}-\d{4}$/))
      contactErrors.phone = 'Invalid phone';

    if (!contact.phone_type_id)
      contactErrors.phone_type_id = 'Required';
  
    if (!contact.email)
      contactErrors.email = 'Required';
    else if (contact.email.indexOf('@')<0)
      contactErrors.email = 'Invalid email';
  
    if (!contact.birth_date) {
      contactErrors.birth_date = 'Required';
    } else {
      const birth_date_local = moment(contact.birth_date); // consume in in local timezone
      const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
      const fifty_years_ago = moment().tz("America/Los_Angeles").subtract(50, 'year').startOf('day');
      // console.log(birth_date.toISOString(), fifty_years_ago.toISOString())
      if (birth_date.isSameOrAfter(fifty_years_ago))
        contactErrors.birth_date = "Thank you for your interest in Senior Services. Participants must be age 50 and older to enroll in our program. Please call us at 323-860-5830 if you have any questions about our program and age requirements.";
    }
  
    if (!contact.custom_1213) // diabled
      contactErrors.custom_1213 = 'Required';
  
    if (!contact.custom_115) // veteran
      contactErrors.custom_115 = 'Required';
  
    if (!contact.gender_id)
      contactErrors.gender_id = 'Required';
  
    if (!contact.custom_1215) // transgender
      contactErrors.custom_1215 = 'Required';
  
    if (!contact.custom_113) // sexual orientation
      contactErrors.custom_113 = 'Required';
  
    if (!contact.custom_1212 || (Array.isArray(contact.custom_1212) && contact.custom_1212.length===0)) // race
      contactErrors.custom_1212 = 'Required';
  
    if (!contact.custom_1214) // homeless
      contactErrors.custom_1214 = 'Required';
  
    if (!contact.custom_1225) // photo permission
      contactErrors.custom_1225 = 'Required';

    if (!contact.custom_1306) // online Liabliity release
      contactErrors.custom_1306 = 'Required';

    // Profile image
    // if (!contact_image || !contact_image[0])
    //   errors.contact_image = "Required";

    /**
     * West Hollywood conditional validation
     */

    if (!weho.live_in_weho) { // live WeHo
      wehoErrors.live_in_weho = 'Required';
    } else if (weho.live_in_weho==="1") {
      if (!weho.live_in_weho_address)
        wehoErrors.live_in_weho_address = 'Required'; // live weho address
      if (!weho.live_in_weho_zip)
        wehoErrors.live_in_weho_zip = 'Required'; // live weho zip
    }
  
    if (!weho.work_in_weho) { // work WeHo
      wehoErrors.work_in_weho = 'Required';
    } else if (weho.work_in_weho==="1") {
      if (!weho.work_in_weho_employer)
        wehoErrors.work_in_weho_employer = 'Required'; // work employer
      if (!weho.work_in_weho_address)
        wehoErrors.work_in_weho_address = 'Required'; // work address
      if (!weho.work_in_weho_zip)
        wehoErrors.work_in_weho_zip = 'Required'; // work address
    }

    if (!weho.property_in_weho) { // property WeHo
      wehoErrors.property_in_weho = 'Required';
    } else if (weho.property_in_weho==="1") {
      if (!weho.property_in_weho_address)
        wehoErrors.property_in_weho_address = 'Required'; // property address
      if (!weho.property_in_weho_zip)
        wehoErrors.property_in_weho_zip = 'Required'; // property zip
    }

    if (!weho.school_in_weho) { // school WeHo
      wehoErrors.school_in_weho = 'Required';
    } else if (weho.school_in_weho==="1") {
      if (!weho.school_in_weho_name)
        wehoErrors.school_in_weho_name = 'Required'; // school name
      if (!weho.school_in_weho_address)
        wehoErrors.school_in_weho_address = 'Required'; // school address
      if (!weho.school_in_weho_zip)
        wehoErrors.school_in_weho_zip = 'Required'; // school zip
    }

    if (!weho.homeless_in_weho) { // homeless WeHo
      wehoErrors.homeless_in_weho = 'Required';
    } else if (weho.homeless_in_weho==="1") {
      if (!weho.homeless_in_weho_address)
        wehoErrors.homeless_in_weho_address = 'Required'; // homeless address
      if (!weho.homeless_in_weho_zip)
        wehoErrors.homeless_in_weho_zip = 'Required'; // homeless zip
      if (!weho.homeless_in_weho_desc)
        wehoErrors.homeless_in_weho_desc = 'Required'; // homeless description
    }
    
    /**
     * Aggrigate errors
     */
    const errorKeys = Object.keys(errors);
    const authErrorKeys = Object.keys(authErrors);
    const contactErrorsKeys = Object.keys(contactErrors);
    const wehoErrorsKeys = Object.keys(wehoErrors);
    if (errorKeys.length>0 || authErrorKeys.length>0 || contactErrorsKeys.length>0 || wehoErrorsKeys.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        ...errors,
        auth: authErrors,
        contact: contactErrors,
        weho: wehoErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    /**
     * Modify some data that needed handled differently in checkboxes
     */

    contact.do_not_phone = contact.do_not_phone ? '1' : '0';
    contact.do_not_email = contact.do_not_email ? '1' : '0';
    contact.do_not_mail = contact.do_not_mail ? '1' : '0';
    contact.do_not_sms = contact.do_not_sms ? '1' : '0';

    const { preferred_communication_method } = contact;
    contact.preferred_communication_method = preferred_communication_method ? preferred_communication_method.map((v,i) => v ? i : false).filter(v => v!==false) : [];

    // photo permissions, liability release
    contact.custom_1306 = contact.custom_1306 ? '1' : '0';

    const birth_date_local = moment(contact.birth_date); // consume in in local timezone
    const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
    contact.birth_date = birth_date.format('YYYY-MM-DD');

    const json = {
      auth,
      contact,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));

    if (contact_image && contact_image[0]) {
      formData.append('contact_image', contact_image[0]);
    }
  
    const url = handleCiviURLBuild('Account', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error) {
        throw new Error(json.error_message);
      }
      handleMessage('Successfully created account. Please check your email for your username and password.', 'success');
      return json;
    })
    .then(contactJson => {

      //Create weho verification activities

      let wehoLivesHasError = false;
      let wehoWorksHasError = false;
      let wehoPropertyHasError = false;
      let wehoSchoolHasError = false;
      let wehoHomelessHasError = false;

      const formattedNow = moment().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');
      const activityUrl = handleCiviURLBuild('Request', 'create', null, contactJson.values.contact.api_key);
      const wehoVerifyPromises = [];

      if (weho.live_in_weho==='1') {
        const livesJson = {
          activity_type_id: 171,
          target_contact_id: contactJson.contact_id,
          subject: "WeHo Resident Verification",
          activity_date_time: formattedNow,
          status_id: "Completed",
          custom_1437: 1, //Verification Type
          custom_1439: weho.live_in_weho_address, //Address
          custom_1440: weho.live_in_weho_zip, //Zip
          custom_1447: 2, //Signature Type
          custom_1444: formattedNow,
        };

        const livesFormData = new FormData();
        livesFormData.append('json', JSON.stringify(livesJson));
        wehoVerifyPromises.push(
          fetch(activityUrl, {
            method: "POST",
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            }, 
            body: livesFormData,
          })
          .then(response => response.json())
          .then(livesResult => {
            if (livesResult.is_error) {
              wehoLivesHasError = true;
              wehoErrors.live_in_weho_address = livesResult.error_message;
              wehoErrors.live_in_weho_zip = livesResult.error_message;
            }
            return livesResult;
          })
        );
      }

      if (weho.work_in_weho==='1') {

        const worksJson = {
          activity_type_id: 171,
          target_contact_id: contactJson.contact_id,
          subject: "WeHo Work Verification",
          activity_date_time: formattedNow,
          status_id: "Completed",
          custom_1437: 1, //Verification Type
          custom_1439: weho.work_in_weho_address, //Address
          custom_1440: weho.work_in_weho_zip, //Zip
          custom_1438: weho.work_in_weho_employer, //Employer (as location name)
          custom_1447: 2, //Signature Type
          custom_1444: formattedNow,
        };

        const worksFormData = new FormData();
        worksFormData.append('json', JSON.stringify(worksJson));
        wehoVerifyPromises.push(
          fetch(activityUrl, {
            method: "POST",
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            }, 
            body: worksFormData,
          })
          .then(response => response.json())
          .then(worksResult => {
            if (worksResult.is_error) {
              wehoWorksHasError = true;
              wehoErrors.work_in_weho_address = worksResult.error_message;
              wehoErrors.work_in_weho_zip = worksResult.error_message;
            }
            return worksResult;
          })
        );
      }

      if (weho.property_in_weho==='1') {

        const propertyJson = {
          activity_type_id: 171,
          target_contact_id: contactJson.contact_id,
          subject: "WeHo Property Verification",
          activity_date_time: formattedNow,
          status_id: "Completed",
          custom_1437: 1, //Verification Type
          custom_1439: weho.property_in_weho_address, //Address
          custom_1440: weho.property_in_weho_zip, //Zip
          custom_1447: 2, //Signature Type
          custom_1444: formattedNow,
        };

        const propertyFormData = new FormData();
        propertyFormData.append('json', JSON.stringify(propertyJson));
        wehoVerifyPromises.push(
          fetch(activityUrl, {
            method: "POST",
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            }, 
            body: propertyFormData,
          })
          .then(response => response.json())
          .then(propertyResult => {
            if (propertyResult.is_error) {
              wehoPropertyHasError = true;
              wehoErrors.property_in_weho_address = propertyResult.error_message;
              wehoErrors.property_in_weho_zip = propertyResult.error_message;
            }
            return propertyResult;
          })
        );
      }

      if (weho.school_in_weho==='1') {

        const schoolJson = {
          activity_type_id: 171,
          target_contact_id: contactJson.contact_id,
          subject: "WeHo School Verification",
          activity_date_time: formattedNow,
          status_id: "Completed",
          custom_1437: 1, //Verification Type
          custom_1447: 2, //Signature Type
          custom_1438: weho.school_in_weho_name, //School Name (as location name)
          custom_1439: weho.school_in_weho_address, //Address
          custom_1440: weho.school_in_weho_zip, //Zip
          custom_1444: formattedNow,
        };

        const schoolFormData = new FormData();
        schoolFormData.append('json', JSON.stringify(schoolJson));
        wehoVerifyPromises.push(
          fetch(activityUrl, {
            method: "POST",
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            }, 
            body: schoolFormData,
          })
          .then(response => response.json())
          .then(schoolResult => {
            if (schoolResult.is_error) {
              wehoSchoolHasError = false;
              wehoErrors.school_in_weho_address = schoolResult.error_message;
              wehoErrors.school_in_weho_zip = schoolResult.error_message;
            }
            return schoolResult;
          })
        );
      }

      if (weho.homeless_in_weho==='1') {
        const homelessJson = {
          activity_type_id: 171,
          target_contact_id: contactJson.contact_id,
          subject: "WeHo Homeless Verification",
          activity_date_time: formattedNow,
          status_id: "Completed",
          custom_1437: 1, //Verification Type
          custom_1439: weho.homeless_in_weho_address, //Address
          custom_1440: weho.homeless_in_weho_zip, //Zip
          custom_1447: 2, //Signature Type
          details: weho.homeless_in_weho_desc, //Description  
          custom_1444: formattedNow,
        };

        const homelessFormData = new FormData();
        homelessFormData.append('json', JSON.stringify(homelessJson));
        wehoVerifyPromises.push(
          fetch(activityUrl, {
            method: "POST",
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
            }, 
            body: homelessFormData,
          })
          .then(response => response.json())
          .then(homelessResult => {
            if (homelessResult.is_error) {
              wehoHomelessHasError = false;
              wehoErrors.homeless_in_weho_address = homelessResult.error_message;
              wehoErrors.homeless_in_weho_zip = homelessResult.error_message;
            }
            return homelessResult;
          })
        );
      }

      return Promise.allSettled(wehoVerifyPromises)
      .then(wehoResponses => {
      
        const updatedContact = contactJson.values.contact;

        /**
        * Add in updated times
        */

        if (!wehoLivesHasError)
          updatedContact.custom_1464 = formattedNow;
        if (!wehoWorksHasError)
          updatedContact.custom_1465 = formattedNow;
        if (!wehoPropertyHasError)
          updatedContact.custom_1466 = formattedNow;
        if (!wehoSchoolHasError)
          updatedContact.custom_1467 = formattedNow;
        if (!wehoHomelessHasError)
          updatedContact.custom_1468 = formattedNow;
  
        // Update last weho checked dates on contact record
  
        const json = {
          auth,
          updatedContact
        };

        const formData = new FormData();
        formData.append('json', JSON.stringify(json));
      
        const url = handleCiviURLBuild('Account', 'create');
  
        return fetch(url, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: formData,
        })
      })
      .then(responses => {
        history.push('/login');
        return responses;
      })
    })
    // .then(json => handleLogin(auth.username, auth.password))
    .catch(error => {
      handleMessage(`There was an issue creating your account (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { handleSubmit, mobileMode, pristine, submitting } = this.props;

    return (
      <div className="Register">
        <div className="p-grid p-justify-center gridContainer">
          <div className ="p-col p-md-4 hasBackground">
            <div className ={cx({ 'droppedCol': !mobileMode })}>
              <h1>Senior Services</h1>
              <p>
                Use this portal to register for 
                events and case management appointments.
              </p>
            </div>
          </div>
          <div className ="p-col p-md-8">
            <div className="fieldContainer">
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
              <hr />
              <Form onSubmit={handleSubmit(this.submitForm)}>
                <h2>My Account</h2>
                <h5>Your username and password will be automatically generated and sent to the email address you provided.</h5>
                {/* <UsernamePassword {...this.props} /> */}
                <hr />
                <h2>My Profile</h2>
                <Profile {...this.props} />
                <hr />
                <h2>Emergency Contact</h2>
                <EmergencyContact {...this.props} />
                <hr />
                <h2>My Preferences</h2>
                <Preferences {...this.props} />
                <hr />
                <h2>Demographic Information</h2>
                <p><em>
                  Information provided to senior services will remain confidential. 
                  The complete and accurate information you provide, assists us in 
                  maintaining and expanding our free and low cost services.  We 
                  appreciate you fully completing the profile information requested.
                </em></p>
                <Demographics {...this.props} />
                <hr />
                <h2>West Hollywood Information</h2>
                <p><em>
                  We work with many funding sources to provide free and low cost 
                  services including the City of West Hollywood. As part of our 
                  agreement with the City, we must verify eligibility for funding.
                </em></p>
                <WeHo {...this.props} />
                <hr />
                <h2>Releases</h2>
                <Releases {...this.props} />
                <hr />
                <div className="form-group">
                  <Button type="submit" className="button" label="Create Account" disabled={pristine || submitting} />
                </div>
              </Form>
              <hr />
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const formName = 'register';

const initialValues = {
  auth: {
    email_password: '1',
    generate_username: '1',
    generate_password: '1',
  },
  contact: {
    contact_type: "Individual",
    contact_sub_type: "Client",
  },
  weho: {},
};

const mapStateToProps = (state) => {
  
  const { display: { mobileMode}, form } = state;

  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    mobileMode,
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
    handleLogin: (username, password) => {
      dispatch(login(username, password));
      dispatch(initialize(formName, initialValues));
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues,
  })(Register)
);