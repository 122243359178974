import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
  updateDateRange,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import { Button } from 'primereact/button';
import { 
  Calendar,
  Dropdown,
  InputText,
  InputTextarea,
  MultiSelect,
  RadioButtonGroup,
} from 'components/FormFields';
import * as moment from 'moment-timezone';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import locationsList from 'helpers/Filter/locations';
import caseManagementTimeOptionsList from 'helpers/Filter/caseManagementTimeOptions';
import caseManagementServicesList from 'helpers/Filter/caseManagementServices';

// import { Calendar as PrimeCalendar } from 'components/Calendar';

class ScheduleAppointment extends Component {

  constructor(props){
    super(props);
    this.state = {
      availableTimeSlots: [],
    };
  }

  componentDidMount() {

    this.loadUnavailableData();

    const { updateDateRange } = this.props;

    const activity_date = moment().tz('America/Los_Angeles').add(4, 'day').startOf('day');
    const end = activity_date.clone().add(1, 'day');
    updateDateRange(activity_date.toISOString(), end.toISOString(), 'casemgmt_unavailable');
    this.props.initialize({
      activity_date: activity_date.toISOString(),
      custom_172: '1', // AMA Campus
    });
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    this.loadUnavailableData();

    const { casemgmt_unavailable_state } = this.props;
    if (casemgmt_unavailable_state !== prevProps.casemgmt_unavailable_state) {
      const availableTimeSlots = this.calculateAvailableTimeSlots();
      this.setState({ availableTimeSlots });
    }

  }

  calculateCurrentListState = () => {
    const listState = [
      this.props.contactId,
      this.props.casemgmt_unavailable.startDate,
      this.props.casemgmt_unavailable.endDate,
      this.props.casemgmt_unavailable.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      this.props.casemgmt_unavailable.pageSize,
      this.props.casemgmt_unavailable.currentPage,
    ].join('|');
    // console.log(this.props.casemgmt_unavailable)
    return listState;
  }

  /**
   * Calculate the available timeslots from the unavailble slots we loaded
   */
  calculateAvailableTimeSlots = () => {

    const { casemgmt_unavailable_data, data } = this.props;
    
    const activity_date_raw = moment(data.activity_date); // consume in in local timezone
    const activity_date = moment.tz(activity_date_raw.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days

    const dayParts = activity_date.format('YYYY-MM-DD').split('-');
    const dow = activity_date.day();

    // console.log(
    //   data.activity_date,
    //   activity_date.format('YYYY-MM-DD HH:mm:ss'),
    //   activity_date.toISOString(),
    //   activity_date,
    //   dow,
    //   dayParts
    // );
    // return []
    
    // no appointments on the weekend
    if (dow===0 || dow===5 || dow===6) // 0 sunday, 5 friday, 6 saturday
      return [];
    
    const now = moment().tz("America/Los_Angeles");

    const availableTimeSlots = caseManagementTimeOptionsList.filter(timeSlotOption => {
      
      const timeParts = timeSlotOption.value.split(':');
      const timeSlot = moment().tz("America/Los_Angeles").set({ 
        year: dayParts[0], month: parseInt(dayParts[1]) - 1, date: dayParts[2],
        hour: timeParts[0], minute: timeParts[1], second: timeParts[2] 
      });

      // console.log(timeSlot.format('YYYY-MM-DD HH:mm:ss'));
      // console.log({casemgmt_unavailable_data, dayParts})
      
      if (!timeSlot.isAfter(now))
        return false;

      const timeSlotEnd = timeSlot.clone().add(timeSlotOption.duration, 'minute');
      const overlapping = casemgmt_unavailable_data.filter(appt => {
      
        const apptDateTime = moment.tz(appt.activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        const apptDateTimeEnd = apptDateTime.clone().add(appt.duration || '45', 'minute');
        // our time slot ends before this appointment or our time slot starts after this appt ends

        const itsAvailable = timeSlotEnd.isBefore(apptDateTime) || timeSlot.isAfter(apptDateTimeEnd);
        // console.log({ itsAvailable }, timeSlot.format('YYYY-MM-DD HH:mm:ss'), apptDateTime.format('YYYY-MM-DD HH:mm:ss'))
      
        return !itsAvailable;
      });
      
      // console.log('overlapping',  timeSlotOption.value, overlapping)
      return overlapping.length===0;
    });

    return availableTimeSlots;
  }

  /**
   * Load unavailable timeslots
   */
  loadUnavailableData = () => {
    
    const currentListState = this.calculateCurrentListState();
    const { casemgmt_unavailable } = this.props;
    const { listState, loadingListState, startDate, endDate } = casemgmt_unavailable;

    if (
      currentListState === listState ||
      currentListState === loadingListState
    ) return;

    this.props.updateLoadingListState(currentListState, 'casemgmt_unavailable');

    // no timezone since we are just dealing with days
    const start = moment(startDate).tz('America/Los_Angeles');
    const end = moment(endDate).tz('America/Los_Angeles');
    // console.log({ startDate, endDate, start, end }, start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss'))

    const url = handleCiviURLBuild('Request','unavailable');
    const json = {
      sequential: 1,
      activity_date_time: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'), end.format('YYYY-MM-DD HH:mm:ss')]
      },
      status_id: {
        IN: ['Scheduled', 'Completed', 'Requested'],
      },
      activity_type_id: '163', // case management walk in
      options:{
        limit: 0,
      },
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
    
    return fetch(url, {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      // console.log(json)
      return json;
    })
    .then(data => data.values)
    .then(data => {
      this.props.setListData({ data }, 'casemgmt_unavailable');
      this.props.updateCurrentListState(currentListState, 'casemgmt_unavailable');
      this.props.updateLoadingListState('', 'casemgmt_unavailable');
    })
    .catch(e => {
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  /**
   * Submit the case mgmt request
   */
  submitForm = values => {

    const { casemgmt_data, contact, handleMessage, initialize, updateCurrentListState } = this.props;

    /**
     * Validate and parse out payload
     */

    // can be used for individual field errors if you throw this object in a submittions error
    const errors = {};

    if (!values.activity_date)
      errors.activity_date = 'Required';

    if (!values.activity_time)
      errors.activity_time = 'Required';

    // generate the activity date time
    if (values.activity_date && values.activity_time) {
      
      const apptDateTime = moment.tz(values.activity_date, 'YYYY-MM-DD', 'America/Los_Angeles');
      const timeParts = values.activity_time.split(':');
      apptDateTime.set({ 
        hour: timeParts[0], minute: timeParts[1], second: timeParts[2] 
      });
      values.activity_date_time = apptDateTime.format('YYYY-MM-DD HH:mm:ss');

      const apptHour = apptDateTime.format('HH');
      if (apptHour<8 || apptHour>16) {
        errors.activity_time = 'Appointment are only allowed between the hours of 8am and 4pm';
      } else if (casemgmt_data && casemgmt_data.length>0) {
        casemgmt_data.forEach(existingAppt => {

          if (!['1', '2', '9'].includes(existingAppt.status_id)) // 'Scheduled', 'Completed','Requested'
            return;
          // console.log(existingAppt)

          const existingApptTime = moment.tz(existingAppt.activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
          const existingApptTimeStart = existingApptTime.clone().startOf('week');
          const existingApptTimeEnd = existingApptTime.clone().endOf('week');
          if (apptDateTime.isAfter(existingApptTimeStart) && apptDateTime.isBefore(existingApptTimeEnd))
            errors.activity_date = 'Only 1 appointment per week';
        });
      } else {
        const minDate = moment().tz('America/Los_Angeles').add(4, 'day').startOf('day');
        const maxDate = moment().tz('America/Los_Angeles').add(30, 'day').endOf('day');
        if (apptDateTime.isBefore(minDate) || apptDateTime.isAfter(maxDate))
          errors.activity_date = 'Appointments can only be scheduled 3 to 30 days in advance';
      }
    }

    if (!values.custom_172) {
      errors.custom_172 = 'Required';
    } else if (values.custom_172==='Other') {
      if (!values.custom_173)
        errors.custom_173 = 'Required';
      if (!values.custom_174)
        errors.custom_174 = 'Required';
    }

    if (!values.custom_1201 || values.custom_1201.length===0)
      errors.custom_1201 = 'Required';
    else if (values.custom_1201.includes('10') && !values.custom_1202)
      errors.custom_1202 = 'Required';

    if (values.details && values.details.length>280) 
      errors.details = 'Comments are too long';

    const errorKeys = Object.keys(errors);
    if (errorKeys.length>0) {
      // throw new SubmissionError(errors);
      throw new SubmissionError(errors);
    }

    /**
     * Submit!
     */

    const json = {
      activity_type_id: '163', // Case Management Walk In Service
      target_contact_id: contact.id,
      status_id: 'Requested',
      duration: '45',
      activity_date_time: values.activity_date_time,
      subject: "Portal - Case Managment Request",
      custom_172: values.custom_172, // location
      custom_173: values.custom_173, // other location name
      custom_174: values.custom_174, // other location address
      custom_1201: values.custom_1201, // services
      custom_1202: values.custom_1202, // other services
      details: values.details ? values.details.replace(/\n/g, "<br />") : '',
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
  
    const url = handleCiviURLBuild('Request','create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Appointment scheduled. Please check in at the front desk the day of your appointment.', 'success');
      const activity_date = moment().tz('America/Los_Angeles').add(4, 'day').startOf('day');
      initialize({
        activity_date: activity_date.toISOString(),
        custom_172: '1', // AMA Campus
      });
      updateCurrentListState(''); // invalidate current list of case management
      updateCurrentListState('', 'casemgmt_unavailable');
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue submitting your request (${error.message})`, 'error');
      return false;
    });
  }

  render() {

    const { casemgmt_unavailable_fetching, data, /* error, */ handleSubmit, submitting, updateDateRange } = this.props;
    const { activity_date } = data;
    const { availableTimeSlots } = this.state;

    return (
      <div className="ScheduleAppointment">
        <Form onSubmit={handleSubmit(this.submitForm)}>
          {/* <PrimeCalendar
            touchUI={true}
            baseZIndex={1000}
            // readOnlyInput={true}
            // showIcon={true}
            // minDate={moment().tz('America/Los_Angeles').add(4, 'day').set({ hour: 9, minute: 0, second: 0 }).toDate()}
            // showTime={true}
            // stepMinute={15}
            // appendTo={document.body}
          /> */}
          <p>
            Appointment must be scheduled at least 3 days in advance.
            If you would like to cancel or change your request, 
            please notify the Center 48 hours before the appointment.
          </p>
          {/* {error && <Message severity="error" text={error} closable={true} duration={5000} />} */}
          <Field
            label="Request Appointment Date"
            id="activity_date" 
            name="activity_date" 
            // disabled={isFetching}
            component={Calendar}
            touchUI={true}
            readOnlyInput={true}
            showIcon={true}
            minDate={moment().tz('America/Los_Angeles').add(4, 'day').startOf('day').toDate()}
            showTime={false}
            onChange={e => {
              if (e.target.value) {
                const calendarStart = moment(e.target.value); // consume in in local timezone
                const start = moment.tz(calendarStart.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
                const end = start.clone().add(1, 'day');
                // console.log(e.target.value)
                // console.log(start.format('YYYY-MM-DD HH:mm:ss'))
                // console.log(startAlt.format('YYYY-MM-DD HH:mm:ss'))
                // console.log(e.target.value, calendarStart.toISOString(), start.toISOString())
                updateDateRange(start.toISOString(), end.toISOString(), 'casemgmt_unavailable');
              }
            }}
            // stepMinute={15}
          />
          
          {activity_date && (
            <div>
              <Field
              label="Requested Time"
              id="activity_time"
              name={`activity_time`}
              component={RadioButtonGroup}
              options={availableTimeSlots}
              />
              {availableTimeSlots.length===0 && (<h3>{casemgmt_unavailable_fetching ? 'Checking Availability' : 'No Available Time Slots'}</h3>)}
            </div>
          )}
          <Field
            label="Appointment Location"
            id="custom_172" 
            name="custom_172" 
            component={Dropdown}
            options={locationsList.filter(location => location.value==='1')} // Only AMA campus is allowed
          />
          {data && 'custom_172' in data && data.custom_172.includes('Other') && // other is selected above
            <div>
              <Field
                label="If other location, please give location name"
                id="custom_173"
                name="custom_173"
                component={InputText}
              />
              <Field
                label="If other location, please give location address"
                id="custom_174"
                name="custom_174"
                component={InputText}
              />
            </div>
          }
          <Field
            label="Reason for Request"
            id="custom_1201" 
            name="custom_1201" 
            component={MultiSelect}
            options={caseManagementServicesList}
          />
          {data && 'custom_1201' in data && data.custom_1201.includes('10') && // other is selected above
            <Field
              label="If other, please describe services"
              id="custom_1202"
              name="custom_1202"
              component={InputText}
            />
          }
          <Field
            label="Comments"
            id="details" 
            name="details" 
            component={InputTextarea}
          />
          <Button type="submit" label="Submit Request" disabled={availableTimeSlots.length===0 || submitting} />
        </Form>
      </div>
    );
  }
}

const listName = 'casemanagement';
const formName = 'scheduleappointment';

const mapStateToProps = (state) => {
  
  const { auth: { contact, contactId }, form, list } = state;
  const loaded = formName in form && 'values' in form[formName];
  
  return {
    contact,
    contactId,
    loaded,
    data: loaded && form[formName].values,
    casemgmt_data: list[listName].data,
    casemgmt_state: list[listName].listState,
    casemgmt_loading_state: list[listName].loadingListState,
    casemgmt_unavailable: list['casemgmt_unavailable'],
    casemgmt_unavailable_data: list['casemgmt_unavailable'].data, // need this so damn thing updates propery
    casemgmt_unavailable_state: list['casemgmt_unavailable'].listState,
    casemgmt_unavailable_loading_state: list['casemgmt_unavailable'].loadingListState,
    casemgmt_unavailable_fetching: list['casemgmt_unavailable'].isFetching,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
    
    updateCurrentPage: (currentPage, currentList=listName) => dispatch(updateCurrentPage(currentList, currentPage)),
    updatePageSize: (pageSize, currentList=listName) => dispatch(updatePageSize(currentList, pageSize)),
    updateSorting: (sortBy, currentList=listName) => dispatch(updateSorting(currentList, sortBy)),
    updateSearchValue: (searchValue, currentList=listName) => dispatch(updateSearchValue(currentList, searchValue)),
    updateCurrentListState: (listState, currentList=listName) => dispatch(updateCurrentListState(currentList, listState)),
    updateLoadingListState: (loadingListState, currentList=listName) => dispatch(updateLoadingListState(currentList, loadingListState)),
    updateDateRange: (start, end, currentList=listName) => dispatch(updateDateRange(currentList, start, end)),
    setListData: (data, currentList=listName) => dispatch(setListData(currentList, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(ScheduleAppointment)
);