import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authSetup } from 'store/actions/auth';
import { windowResize } from 'store/actions/display';
import { loadStatusMap } from 'store/actions/load';

import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App/styles.css';

import LoadingOverlay from './App/LoadingOverlay';

import Header from './App/Header';
import Footer from './App/Footer';
import Messages from './App/Messages';
import DataEnrichment from './App/DataEnrichment/WeHo';

import Account from './Account';
import CaseManagement from './CaseManagement';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Logout from './Logout';
import NotFound from './NotFound';
import Register from './Register';
import Requests from './Requests';
import ResetPassword from './ResetPassword';
import RSVP from './RSVP';
import SidebarMenu from './App/SidebarMenu';
import StillHere from './App/StillHere';

import ReactPiwik from 'react-piwik';
const piwik = new ReactPiwik({
  url: 'stats.vanguardnow.org',
  siteId: 18,
  trackErrors: true,
});

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class App extends Component {

  unlisten = null;

  constructor(props){
    super(props);
    this.state = {
      stillHereTimeout: null,
      stillHereOpen: false,
      dataEnrichmentOpen: false,
    };
  }

  componentDidMount() {

    piwik.connectToHistory(this.props.history);
    // this.unlisten = this.props.history.listen((location, action) => {
    //   // location is an object like window.location
    //   // console.log(action, location.pathname, location.state);
    // });
    
    this.props.handleAuthSetup();
    this.props.handleLoadStatusMap();
    this.resize();

    const { loggedIn } = this.props;
    
    if (loggedIn)
      this.addStillHereEventListeners();

    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    const { loggedIn } = this.props;
    
    if (!prevProps.loggedIn && loggedIn) {
      
      this.addStillHereEventListeners();
    
    } else if (prevProps.loggedIn && !loggedIn) {
      
      if (this.state.stillHereTimeout!==null) {
        clearTimeout(this.state.stillHereTimeout);
        this.setState({ stillHereTimeout: null });
      }

      this.removeStillHereEventListeners();

      if (this.state.stillHereOpen)
        this.setState({ stillHereOpen: false });
    }

    // if (this.unlisten)
    //   this.unlisten();

  }

  componentWillUnmount() {
    if (this.state.stillHereTimeout!==null)
      clearTimeout(this.state.stillHereTimeout);

    this.removeStillHereEventListeners();

    window.removeEventListener('resize', this.resize);
  }

  resize = ()=>{
    const
      w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.handleWindowResize(width,height);
  }

  addStillHereEventListeners = () => {
    this.setupStillHereTimeout();
    const handler = e => {
      this.setupStillHereTimeout();
    }
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  removeStillHereEventListeners = () => {
    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });
  }

  setupStillHereTimeout = () => {
    
    if (this.state.stillHereTimeout!==null)
      clearTimeout(this.state.stillHereTimeout);

    const stillHereTimeout = setTimeout(() => this.toggleStillHere(), 900000); // 15 mintues
    this.setState({ stillHereTimeout });
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen)
      this.setupStillHereTimeout();

    this.setState({ stillHereOpen });
  }
  
  render() {

    const { loading, loggedIn, setup } = this.props;
    const { dataEnrichmentOpen, stillHereOpen } = this.state;

    return (
      <div className="App">
        <Header />
        {setup && // somethings rely on status map, dont route until that is loaded
          (loggedIn ?
            <>
              <div className="container">
                <Switch>
                  <Route exact path='/' component={props => {
                    return <Redirect to={{ pathname: "/rsvp", state: { from: props.location } }} />;
                  }} />
                  <Route exact path="/rsvp" component={RSVP} />
                  <Route exact path="/case-management" component={CaseManagement} />
                  <Route exact path="/requests" component={Requests} />
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/logout" component={Logout} />
                  <Route component={props => {
                    switch (props.location.pathname) {
                      case '/login':
                      case '/register':
                      case '/forgot-password':
                        return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
                      default:
                        return <NotFound />
                    }
                  }} />
                </Switch>
                <DataEnrichment open={dataEnrichmentOpen} toggle={newState => this.setState({ dataEnrichmentOpen: newState })} />
                <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
              </div>
              <Route path='*' component={SidebarMenu} />
            </>
          :
            <div className="containerLoggedOut">
              <Switch>
                <Route exact path='/' component={props => {
                  return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                }} />
                <Route exact path='/login' component={Login} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/reset-password/:password_reset_key' component={ResetPassword} />
                <Route component={props => {
                  switch (props.location.pathname) {
                    case '/rsvp':
                    case '/case-management':
                    case '/requests':
                    case '/account':
                    case '/logout':
                      return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
                    default:
                      return <NotFound />
                  }
                }} />
              </Switch>
            </div>
          )
        }
        <Footer />
        <Messages />
        {loading && <LoadingOverlay />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, form, list, load, save, single } = state;
  const { loggedIn, testMode } = auth;
  const { setup } = load;
  return {
    loading: // show our loading overlay
      !setup ||
      auth.isFetching ||
      Object.keys(form).filter(formName => form[formName].submitting).length>0 ||
      Object.keys(list).filter(listName => list[listName].isFetching).length>0 ||
      single.account.isFetching ||
      save.register.isFetching
    ,
    loggedIn,
    setup,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleAuthSetup: () => dispatch(authSetup()),
    handleLoadStatusMap: () => dispatch(loadStatusMap()),
    handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
