const sexualOrientations = [
  {
    label: 'Asexual',
    value: 'Asexual',
  },
  {
    label: 'Bisexual',
    value: 'Bisexual',
  },
  {
    label: 'Straight',
    value: 'Straight',
  },
  {
    label: 'Lesbian',
    value: 'Lesbian',
  },
  {
    label: 'Gay',
    value: 'Gay',
  },
  {
    label: 'Queer',
    value: 'Queer',
  },
  {
    label: 'Declined to State',
    value: '1',
  },
];

export default sexualOrientations;