import initialState from "../initialState";
import constants from "../constants";
import { cloneDeep } from 'lodash-es';

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const authReducer = (state = initialState.auth, action) => {
  // console.log("Running action:", action);
  const newState = {...state};

  switch(action.type)
  {
    case constants.LOGIN:
    case constants.LOGOUT:
    case constants.AUTH_SETUP:
    case constants.TEST_MODE:
    case constants.SINGLE_DATA:
      switch (action.type) {
        case constants.LOGIN:
          const { loggedInApiKey, contactId, contact } = action.value;
          newState.isFetching = false;
          newState.loggedIn = true;
          newState.loggedInApiKey = loggedInApiKey;
          newState.contactId = contactId;
          newState.contact = cloneDeep(contact);
          break;
        case constants.LOGOUT:
          newState.isFetching = false;
          newState.loggedIn = false;
          break;
        case constants.TEST_MODE:
          newState.testMode = action.value;
          break;
        case constants.SINGLE_DATA:
          if (action.value.single === 'account') { // new contact data, might as well use it
            newState.contact = action.value.data.contact ? cloneDeep(action.value.data.contact) : null;
          }
          break;
        default:
          break;
      }
      newState.siteURL = newState.testMode ? newState.siteURLDev : newState.siteURLLive;
      newState.apiKey = newState.loggedIn ? newState.loggedInApiKey : newState.defaultApiKey;
      newState.contactId = newState.loggedIn ? newState.contactId : null;
      newState.contact = newState.loggedIn ? newState.contact : null;
      newState.loggedInApiKey = newState.loggedIn ? newState.loggedInApiKey : null;
      return newState;
    case constants.TRY_LOGIN:
      newState.isFetching = true;
      return newState;
    case constants.FAIL_LOGIN:
      newState.isFetching = false;
      return newState;
    default:
      return newState;
  }
}

export default authReducer;