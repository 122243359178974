const boolean = [
  {
    label: 'No',
    value: '0',
  },
  {
    label: 'Yes',
    value: '1',
  },
];

export default boolean;