import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, } from 'redux-form';
import { Checkbox } from 'components/FormFields';

class Preferences extends Component {
  
  render() {

    return (
      <div className="MyPreferences">
        <div className="p-grid">
          <div className="p-col p-lg-6">
            <h4>Privacy</h4>
            <Field
              label="Do not phone"
              name="contact[do_not_phone]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Do not email"
              name="contact[do_not_email]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Do not mail"
              name="contact[do_not_mail]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Do not text"
              name="contact[do_not_sms]" 
              // disabled={isFetching}
              component={Checkbox}
            />
          </div>
          <div className="p-col p-lg-6">
            <h4>Preferred Communication Method</h4>
            <Field
              label="Phone"
              name="contact[preferred_communication_method][1]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Email"
              name="contact[preferred_communication_method][2]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Postal mail"
              name="contact[preferred_communication_method][3]" 
              // disabled={isFetching}
              component={Checkbox}
            />
            <Field
              label="Text"
              name="contact[preferred_communication_method][4]" 
              // disabled={isFetching}
              component={Checkbox}
            />
          </div>
        </div>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {
  const single = state.single[singleName];
  return {
    ...single,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Preferences);