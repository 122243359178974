import React from 'react';

const PhotoPermission = props => (
  <div className="PhotoPermission">
    <p>
      I agree to grant to the L.A. LGBT Center and its authorized representatives permission to record on photography film and/or video, pictures of my participation. I further agree that any or all the material photographed may be used, in any form, as part of any future print and/or online publications or materials used to promote the L.A. LGBT Center, and further that such use shall be without payment of fees, royalties, special credit or other compensation.
    </p>
  </div>
);

export default PhotoPermission;