import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateDateRange, updateSpecialValue } from 'store/actions/list';
import * as moment from 'moment-timezone';
import cx from 'classnames';

class DateSelect extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: null,
      startDate: null,
      endDate: null,
      weekDates: [],
      specialValue: false,
    };
  }

  componentDidMount() {

    this.updateDates();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    const { date, startDate, endDate, specialValue } = this.props;

    if (
      (
        (date===null && prevProps.date!==null) ||
        (date!==null && prevProps.date===null) ||
        (date!==null && prevProps.date!==null && !moment(date).isSame(moment(prevProps.date))) 
      ) ||
      !moment(startDate).isSame(moment(prevProps.startDate)) ||
      !moment(endDate).isSame(moment(prevProps.endDate)) ||
      prevProps.specialValue !== specialValue
    ) {
      this.updateDates();
    }
  }

  updateDates = () => {

    const { date, startDate, endDate } = this.props;

    const dateMoment = date===null ? null : moment(date).tz('America/Los_Angeles');
    const startDateMoment = moment(startDate).tz('America/Los_Angeles');
    const endDateMoment = moment(endDate).tz('America/Los_Angeles');

    const { weekDates } = this.getDates(startDateMoment, endDateMoment);

    this.setState({
      date: dateMoment,
      startDate: startDateMoment,
      endDate: endDateMoment,
      weekDates,
    });
  }

  getDates = (startDate, endDate) => {
    
    const weekDates = [];

    if (startDate.isAfter(endDate)) 
      return { weekDates };

    const dow = startDate.clone();    
    while(dow.isSameOrBefore(endDate)) {
      weekDates.push(dow.clone());
      dow.add(1, 'day');
    }

    return { weekDates };
  }

  updateDateRange = direction => {

    const { startDate, endDate } = this.state;

    const newStartDate = startDate.clone();
    const newEndDate = endDate.clone();

    if (direction>0) {
      newStartDate.add(1, 'week');
      newEndDate.add(1, 'week');
    } else if (direction<0) {
      newStartDate.add(-1, 'week');
      newEndDate.add(-1, 'week');
    }

    this.props.handleUpdateDateRange(newStartDate.toISOString(), newEndDate.toISOString(), null);
  }

  updateDate = date => {

    const { startDate, endDate } = this.state;
    const newDate = date===null ? null : moment(date);

    this.props.handleUpdateDateRange(startDate.toISOString(), endDate.toISOString(), newDate===null ? null : newDate.toISOString());
  }

  updateSpecialValue = specialValue => {
    this.setState({
      specialValue: specialValue,
    });
    this.props.handleUpdateSpecialValue(specialValue);
  }

  render() {
    
    const { date, startDate, endDate, weekDates, specialValue } = this.state;

    const today = moment().tz('America/Los_Angeles').startOf('day');
    const endOfMonth = moment().tz('America/Los_Angeles').endOf('month');

    const showStartDate = startDate && startDate.isAfter(today);
    const showEndDate = endDate && endDate.isBefore(endOfMonth);

    return (
      <div className="DateSelect">
        <div className="p-grid p-justify-center p-align-center">
          <div className={cx({'p-col': true, 'p-col-1': true, 'change-date': showStartDate })} onClick={() => {
            this.updateSpecialValue(false);
            return showStartDate && this.updateDateRange(-1);
          }}>
            {showStartDate && <span className="pi pi-angle-left"></span>}
          </div>

          <div 
            key="0" 
            className={cx({
              "p-col": true,
              "p-col-1": true,
              "week-days-filter": true,
              "selected-day-filter": specialValue,
              "special-events": true,
            })}
            onClick={() => {
              if (!specialValue) {
                this.updateSpecialValue(true);
                this.updateDate(today.toISOString());
              } else {
                this.updateSpecialValue(false);
                this.updateDate(null);
              }
            }}
          >
            <div
              className={cx({
                "special-highlight": true,
              })}
            > 
              Special<br />Events
            </div>
          </div>

          {weekDates
          .filter(dow => dow.isBefore(endOfMonth))
          .map((dow,i) => (
              <div 
                key={i+1} 
                className={cx({
                  "p-col": true,
                  "p-col-1": true,
                  "week-days-filter": true,
                  "selected-day-filter": date!==null && date.isSame(dow) && !specialValue,
                })}
                onClick={() => {
                  if (date===null || !date.isSame(dow) || specialValue) {
                    this.updateSpecialValue(false);
                    this.updateDate(dow.toISOString());
                  } else {
                    this.updateDate(null);
                  }
                }}
              >
                <div
                  className={cx({
                    "highlight": dow.isSame(today),
                  })}
                > 
                  {dow.format('ddd')}<br />{dow.format('MMM D')}
                </div>
              </div>
          ))}
          <div className={cx({'p-col': true, 'p-col-1': true, 'change-date': showEndDate })} onClick={() => {
            this.updateSpecialValue(false);
            return showEndDate && this.updateDateRange(1);
          }}>
            {showEndDate && <span className="pi pi-angle-right"></span>}
          </div>
        </div>
      </div>
    )
  }
}

const listName = 'rsvp';

const mapStateToProps = (state) => {
  const list = state.list[listName];
  return {
    ...list,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleUpdateDateRange: (startDate, endDate, date = null) => dispatch(updateDateRange(listName, startDate, endDate, date)),
    handleUpdateSpecialValue: (specialValue) => dispatch(updateSpecialValue(listName, specialValue)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DateSelect);