import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateLocations, updateStatues, updateSearchValue } from 'store/actions/list';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import locationsList from 'helpers/Filter/locations';
import statusesList from 'helpers/Filter/statuses';

const WAIT_INTERVAL = 500;
let searchTimeout;

class Filter extends Component {

  constructor(props) {
    super(props);

    const { searchValue } = props;
    this.state = {
      searchValue,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // something updated our search value and it is not the same as what we are currently displaying, such as a clear filters call
    const { searchValue } = this.props;
    if ( prevProps.searchValue !== searchValue && this.state.searchValue !== searchValue)
      this.setState({ searchValue })
  }

  componentWillUnmount() {
    clearTimeout(searchTimeout);
  }

  setSearchValue = (searchValue) => {
    clearTimeout(searchTimeout);
    this.setState({ searchValue });
    searchTimeout = setTimeout(() => this.props.handleUpdateSearchValue(this.state.searchValue), WAIT_INTERVAL);
  }

  render() {

    const { data, handleClearFilters, handleUpdateLocations, handleUpdateStatues, locations, statuses } = this.props;
    const { searchValue } = this.state;

    // filter filter list by those currently on the list
    const filteredStatusesList = statusesList.filter(v => data && data.find(w => w.rsvp_status_filter===v.value));
    const filteredLocationsList = locationsList.filter(v => data && data.find(w => w.event_location_value===v.value));

    return (
      <div className="Filter">
        <div className="p-grid p-justify-center filters-container">
          <div className="p-col p-lg-3">
            <InputText
              value={searchValue}
              type="text"
              onChange = {e => this.setSearchValue(e.target.value)}
              placeholder="Search..."
            />
          </div>
          {filteredLocationsList.length>0 && 
            <div className="p-col p-lg-3">
              <MultiSelect
                value={locations.filter(v => filteredLocationsList.find(w => w.value===v))}
                placeholder="Location"
                options={filteredLocationsList} 
                onChange={e => handleUpdateLocations(e.value)}
                />
            </div>
          }
          {filteredStatusesList.length>0 &&
            <div className="p-col p-lg-3">
              <MultiSelect
                value={statuses.filter(v => filteredStatusesList.find(w => w.value===v))}
                placeholder="RSVP Status"
                options={filteredStatusesList} 
                onChange={e => handleUpdateStatues(e.value)}
              />
            </div>
          }
          <div className="p-col p-lg-3">
            <span className="clear-filters fake-link" onClick={() => handleClearFilters()}>Clear Filters</span>
          </div>
        </div>
      </div>
    )
  }
}

const listName = 'rsvp';

const mapStateToProps = (state) => {
  const list = state.list[listName];
  return {
    ...list,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleUpdateLocations: (locations) => dispatch(updateLocations(listName, locations)),
    handleUpdateStatues: (statuses) => dispatch(updateStatues(listName, statuses)),
    handleUpdateSearchValue: (searchValue) => dispatch(updateSearchValue(listName, searchValue)),
    handleClearFilters: () => {
      dispatch(updateSearchValue(listName, ''));
      dispatch(updateLocations(listName, []));
      dispatch(updateStatues(listName, []));
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Filter);