const ada = [
  {
    label: 'Sign Language',
    value: '1',
  },
  {
    label: 'Visual Aid/Visual Impairment',
    value: '2',
  },
  {
    label: 'Wheelchair accessible vehicle',
    value: '3',
  },
  {
    label: 'Other',
    value: '4',
  },
];

export default ada;