import initialState, { defaultState } from "../initialState";
import constants from "../constants";

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const saveReducer = (state = initialState.save, action) => {
  // console.log("Running action:", action);
  const newState = {...state};
  
  switch(action.type)
  {
    case constants.SAVE_START:
      newState[action.value.save].isFetching = true;
      return newState;
    case constants.SAVE_END:
      newState[action.value.save].isFetching = false;
      return newState;
    case constants.LOGOUT:
      return {...defaultState.save};
    default:
      return newState;
  }
}

export default saveReducer;