import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { InputText } from 'components/FormFields';
import { phoneMask } from 'helpers/Masks';

class EmergencyContact extends Component {

  render() {

    return (
      <div className="EmergencyContact">
        <div className="p-grid">
          <div className="p-col p-lg-4">
            <Field
              label="Contact Name"
              name="contact[custom_127]" 
              // disabled={isFetching}
              component={InputText}
            />
          </div>
          <div className="p-col p-lg-4">
            <Field
              label="Phone *"
              name="contact[custom_128]" 
              // disabled={isFetching}
              component={InputText}
              {...phoneMask}
            />
          </div>
          <div className="p-col p-lg-4">
            <Field
              label="Contact Relationship"
              name="contact[custom_170]" 
              // disabled={isFetching}
              component={InputText}
            />
          </div>
        </div>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {

  const { auth: { siteURL, testMode }, single } = state;

  return {
    ...single[singleName],
    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EmergencyContact);