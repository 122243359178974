import React, { Component } from 'react';
import { connect } from 'react-redux';
import activityStatusList from 'helpers/Filter/activityStatuses';
import CancelAppointment from './CancelAppointment';
import { updateCurrentListState } from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { URLBuild as handleCiviURLBuild, RequestStatusDisplay } from 'helpers/CiviCRM';
import * as moment from 'moment-timezone';

class UpcomingAppointments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cancelOpen: false,
      cancelActivity: null,
      cancelContinue: null,
    };
  }

  cancelActivity = activity => {

    const { handleMessage, updateCurrentListState } = this.props;
    
    const { id, activity_date_time } = activity;

    const cancelTime = moment().tz("America/Los_Angeles").add(48, 'hour');
    const apptTime = moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");

    if (apptTime.isBefore(cancelTime)) {
      handleMessage(`
        Unable to cancel request. 
        If you would like to cancel or change your request, 
        please notify the Center 48 hours before the event.
      `, 'error');
      return false;
    }

    const json = {
      id,
      status_id: 'Cancelled',
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));
  
    const url = handleCiviURLBuild('Request', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('You appointment has been cancelled.', 'success');
      updateCurrentListState(''); // invalidate current list of unavailalbe timeslots
      return json;
    })
    .catch(error => {
      handleMessage(`Unable to cancel appointment (${error.message}).`, 'error');
      return false;
    });
  }

  toggleCancelOpen = (state=null, cancelActivity=null, cancelContinue=null) => 
    this.setState({ cancelOpen: state===null ? !this.state.cancelOpen : state, cancelActivity, cancelContinue });

  render() {

    const { data } = this.props;
    const { cancelOpen, cancelActivity, cancelContinue } = this.state;

    if (data===null)
      return (
        <h3>Loading...</h3>  
      );

    if (data.length===0)
        return (<h3>You have no upcoming appointments.</h3>);

    const activityList =  data.map(activity => {
      const { activity_location, date_display, duration, status_id } = activity;

      const apptStatus = activityStatusList.find(status => status.value === status_id);

      return (
        <div key={activity.id}>
          <h4>{date_display}</h4>
          {duration && <div>Duration: {duration} mintues</div>}
          {apptStatus && 
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>Status:</td>
                    <td><RequestStatusDisplay activity={activity} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          }
          {activity_location && <div>Location: {activity_location}</div>}
          {apptStatus && apptStatus.label!=='Cancelled' && 
            <div
              className="fake-link"
              onClick={() => this.toggleCancelOpen(true, activity, () => { 
                this.toggleCancelOpen(false);
                this.cancelActivity(activity);
              })}
            >
              Need to cancel appointment?
            </div>
          }
        </div>
      );
    })
    .reduce((prev, curr, i) => i>0 ? [prev, <hr key={'br-'+curr.key} />, curr] : curr, '');

    return (
      <div className="UpcomingAppointment">
        {activityList}
        <hr />
        <p><em>Requests will be reviewed. When approved, you will receive a confirmation.</em></p>
        <CancelAppointment open={cancelOpen} toggle={this.toggleCancelOpen} activity={cancelActivity} onContinue={cancelContinue} />
      </div>
    );
  }
}

const listName = 'casemanagement';

const mapStateToProps = (state) => {

  const { 
    auth: { contact, contactId },
    display: { mobileMode }, 
    list,
  } = state;

  return {
    contact,
    contactId,
    ...list[listName],
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
    updateCurrentListState: state => dispatch(updateCurrentListState(listName, state)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(UpcomingAppointments);
