import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menuOpen } from 'store/actions/display';
import { Menu } from 'primereact/menu';
import { Sidebar } from 'primereact/sidebar';
import menuitems from './TitleBar/menuitems';

class SidebarMenu extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { handleMenuOpen, mobileMode, menuOpen } = this.props;
    if (prevProps.mobileMode && !mobileMode && menuOpen)
      handleMenuOpen(false);

  }

  render() {

    const { history, location, handleMenuOpen, menuOpen } = this.props;

    const menuitemscommand = menuitems.map(menuitem => {
      menuitem.command = e => {
        handleMenuOpen(false);
        location.pathname !== e.item.to && history.push(e.item.to);
      }
      menuitem.style= location.pathname.indexOf(menuitem.to)===0 ? { background: '#ccc' } : {};
      return menuitem;
    });

    return (
      <Sidebar visible={menuOpen} onHide={(e) => handleMenuOpen(false)}>
        <Menu model={menuitemscommand} />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  const { contact } = state.auth;
  const { menuOpen, mobileMode } = state.display;
  return {
    contact,
    menuOpen,
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMenuOpen: open => dispatch(menuOpen(open)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);