/**
 * Used on Case Conference activity type 90
 */

const activityStatuses = [
  {
    label: 'Scheduled',
    value: '1',
    color: '#00C781', // status-ok
  },
  {
    label: 'Completed',
    value: '2',
    color: '#A2423D', // neutral-4
  },
  {
    label: 'Cancelled',
    value: '3',
    color: '#777777', // dark-3
  },
  {
    label: 'Left Message',
    value: '4',
    color: '#FF4040', // status-error
  },
  {
    label: 'Unreachable',
    value: '5',
    color: '#FF4040', // status-error
  },
  {
    label: 'Not Required',
    value: '6',
    color: '#CCCCCC', // status-disabled
  },
  {
    label: 'Available',
    value: '7',
    color: '#00739D', // neutral-3
  },
  {
    label: 'No-show',
    value: '8',
    color: '#FF4040', // status-error
  },
  {
    label: 'Requested',
    value: '9',
    color: '#00739D', // neutral-3
  },
];

export default activityStatuses;