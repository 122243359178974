import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Dropdown, InputText } from 'components/FormFields';
import booleanList from 'helpers/Filter/boolean';

class WeHo extends Component {
  
  render() {

    const { 
      formData,
      formLoaded,
      show,
    } = this.props;
    
    const showLivesWeHo = !show || show.wehoLives;
    const showWorksWeHo = !show || show.wehoWorks;
    const showPropertyWeHo = !show || show.wehoProperty;
    const showSchoolWeHo = !show || show.wehoSchool;
    const showHomelessWeHo = !show || show.wehoHomeless;

    return (
      <div className="WeHo">
        <div className="p-grid">
          {showLivesWeHo ?
            <div className="p-col p-lg-6 live-weho">
              <Field
                label="Do you live in West Hollywood? *"
                id="live_in_weho" 
                name="weho[live_in_weho]" 
                // disabled={isFetching}
                component={Dropdown}
                options={booleanList}
              />
              {formLoaded && formData.weho.live_in_weho==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="weho[live_in_weho_address]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Zip *"
                    name="weho[live_in_weho_zip]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <hr />
                </>
              : null} 
            </div>
          : null}
          {showWorksWeHo ?
            <div className="p-col p-lg-6 work-weho">
              <Field
                label="Do you work in West Hollywood? *"
                id="work_in_weho" 
                name="weho[work_in_weho]" 
                // disabled={isFetching}
                component={Dropdown}
                options={booleanList}
              />
              {formLoaded && formData.weho.work_in_weho==="1" ?
                <>
                  <Field
                    label="Employer *"
                    name="weho[work_in_weho_employer]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Street Address *"
                    name="weho[work_in_weho_address]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Zip *"
                    name="weho[work_in_weho_zip]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <hr />
                </>
              : null}
            </div>
          : null}
          {showPropertyWeHo ?
            <div className="p-col p-lg-6 property-weho">
              <Field
                label="Do you own property in West Hollywood? *"
                id="property_in_weho" 
                name="weho[property_in_weho]" 
                // disabled={isFetching}
                component={Dropdown}
                options={booleanList}
              />
              {formLoaded && formData.weho.property_in_weho==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="weho[property_in_weho_address]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Zip *"
                    name="weho[property_in_weho_zip]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <hr />
                </>
              : null}
            </div>
          : null}
          {showSchoolWeHo ?
            <div className="p-col p-lg-6 school-weho">
              <Field
                label="Do you attend school in West Hollywood? *"
                id="school_in_weho" 
                name="weho[school_in_weho]" 
                // disabled={isFetching}
                component={Dropdown}
                options={booleanList}
              />
              {formLoaded && formData.weho.school_in_weho==="1" ?
                <>
                  <Field
                    label="School Name *"
                    name="weho[school_in_weho_name]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Street Address *"
                    name="weho[school_in_weho_address]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Zip *"
                    name="weho[school_in_weho_zip]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <hr />
                </>
              : null}
            </div>
          : null}
          {showHomelessWeHo ?
            <div className="p-col p-lg-6 homeless-weho">
              <Field
                label="Do you spend time homeless in West Hollywood? *"
                id="homeless_in_weho" 
                name="weho[homeless_in_weho]" 
                // disabled={isFetching}
                component={Dropdown}
                options={booleanList}
              />
              {formLoaded && formData.weho.homeless_in_weho==="1" ?
                <>
                  <Field
                    label="Street Address *"
                    name="weho[homeless_in_weho_address]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Zip *"
                    name="weho[homeless_in_weho_zip]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Description *"
                    name="weho[homeless_in_weho_desc]" 
                    // disabled={isFetching}
                    component={InputText}
                  />
                  <hr />
                </>
              : null}
            </div>
          : null}
        </div>
      </div>
    );
  }
}

const singleName = 'account';

const mapStateToProps = (state) => {

  const { auth: { siteURL, testMode }, single } = state;

  return {
    ...single[singleName],

    siteURL,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WeHo);