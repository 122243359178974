import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Field, Form, reduxForm, submit, SubmissionError } from 'redux-form';
import { eventRegister } from 'store/actions/save';
import { RSVPStatusDisplay } from 'helpers/CiviCRM';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { dateDisplay } from 'helpers/CiviCRM';
import { Checkbox, /* CheckboxGroup, */ InputTextarea } from 'components/FormFields';
import adaList from 'helpers/Filter/ada';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import * as moment from 'moment-timezone';

class EventDetails extends Component {

  submitForm = values => {

    const { contact, event, handleEventRegister, handleMessage, reset, toggle } = this.props;

    // const now = moment().tz('America/Los_Angeles');

    /**
     * Validation
     */

    const errors = {};

    let apptTime;
    if (values.needs_ada) {

      // Prefill values from event
      values.activity_date_time = event.event_start_date;
      values.custom_172 = event.custom_1198;
      
      if (!values.activity_date_time) {
        errors.activity_date_time = 'Date and time is Required';
      } else {
        apptTime = moment.tz(values.activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
        // const apptHour = apptTime.format('HH');
        // if (apptHour<8 || apptHour>17) {
        //   errors.activity_date_time = 'Appointment are only allowed between the hours of 8am and 5pm';
        // } else {
        //   const minDate = moment().tz('America/Los_Angeles').add(11, 'day').startOf('day');
        //   // const maxDate = moment().tz('America/Los_Angeles').add(30, 'day').endOf('day');
        //   if (apptTime.isBefore(minDate) /* || apptTime.isAfter(maxDate) */)
        //     errors.activity_date_time = 'ADA Requests can only be made 10 days in advance';
        // }
      }

      if (!values.custom_172) {
        errors.custom_172 = 'Location is Required';
      } else if (values.custom_172==='Other') {
        // if (!values.custom_173)
        //   errors.custom_173 = 'Required';
        // if (!values.custom_174)
        //   errors.custom_174 = 'Required';
      }

      if (!values.custom_1247 || values.custom_1247.filter(v => v).length===0)
        errors.custom_1247 = 'Requested Accommodations are Required';

      if (values.details && values.details.length>280) 
        errors.details = 'Comments are too long';
      
    }

    const errorKeys = Object.keys(errors);
    if (errorKeys.length>0) {
      // throw new SubmissionError(errors);
      throw new SubmissionError({
        _error: errorKeys.map(key => errors[key]).join(', '),
      });
    }

    /**
     * Event Register
     */
    handleEventRegister(event);

    /**
     * ADA Requests
     */
    if (values.needs_ada) {

      const json = {
        activity_type_id: '162', // Case Management Walk In Service
        target_contact_id: contact.id,
        status_id: 'Requested',
        activity_date_time: apptTime.format('YYYY-MM-DD HH:mm:ss'),
        subject: `Portal - ADA Request (${event.event_title})`,
        custom_172: values.custom_172, // location
        // custom_173: values.custom_173, // other location name
        // custom_174: values.custom_174, // other location address
        custom_1247: values.custom_1247.map((v,i) => v ? i : false).filter(v => v!==false), // ADA services
        details: values.details ? values.details.replace(/\n/g, "<br />") : '',
      };

      const formData = new FormData();
      formData.append('json', JSON.stringify(json));
    
      const url = handleCiviURLBuild('Request','create');

      fetch(url, {
        method: "POST",
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        }, 
        body: formData,
      })
      .then(response => response.json())
      .then(json => {
        // console.log(json);
        if (json.is_error) {
        throw new Error(json.error_message);
        }
        // handleMessage('ADA request submitted.', 'success');
        return json;
      })
      .catch(error => {
        handleMessage(`There was an issue submitting your ADA request (${error.message})`, 'error');
        return false;
      });
    }

    /**
     * close popup, reset form
     */
    reset();
    toggle(false);
  }

  render() {

    const { data, error, event, handleSubmit, handleSubmitForm, open, reset, toggle, toggleCancel } = this.props;

    if (!event)
      return null;

    const { rsvp_status } = event;

    // const now = moment().tz('America/Los_Angeles');
    // const minADADate = moment().tz('America/Los_Angeles').add(11, 'day').startOf('day');
    // const maxADADate = moment().tz('America/Los_Angeles').add(30, 'day').endOf('day');
    // const ADAAllowed = !(now.isBefore(minADADate) || now.isAfter(maxADADate));
    const ADAAllowed = true;

    return (
      <Dialog 
        className="EventDetails"
        visible={open}
        maximizable={true}
        onHide={() => {
          reset();
          toggle(false);
        }}
        header={event.event_title}
      >
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="p-grid">
            <div className="p-col p-md-6">
              <dl>
                <dt>Date and Time</dt>
                <dd>{dateDisplay(event)}</dd>
                <dt>Location</dt>
                <dd>
                  {
                    event.event_location ? 
                    (
                      event.event_location +
                      (event.event_location==='Other' && event.event_location_address ? ` (${event.event_location_address})` : '')
                    ) : '-- ask for location --'
                  }
                </dd>
                <dt>Room</dt>
                <dd>{event.room_location ? event.room_location : '-- ask for room --'}</dd>
                <dt>Drop in Allowed</dt>
                <dd>{event.custom_1246==='1' || event.custom_1246==='3' ? 'Yes' : 'No'}</dd>
              </dl>
            </div>
            <div className="p-col p-md-6 text-right">
              <dl>
                <dt>RSVP Status</dt>
                <dd>
                  <RSVPStatusDisplay rsvp_status={event.rsvp_status} />
                </dd>
              </dl>
            </div>
          </div>
          {
            !['unavailable','past','registered','waitlisted','pendingapproval','pendingattended']
            .includes(rsvp_status) &&
            <div>
              <div>
                {ADAAllowed ?
                  <Field
                    label="Do you need special accommodations?"
                    id="needs_ada" 
                    name="needs_ada" 
                    component={Checkbox}
                    disabled={!ADAAllowed}
                  />
                : 
                  <p>ADA Requests unavailable. Requests must be made 10 days in advance.</p>
                }
              </div>
              {ADAAllowed && data && data.needs_ada && 
                <div className="boxed adarequests">
                  <fieldset>
                    <legend>ADA Accommodation(s) Requested</legend>
                    <p><em>
                      In general, ADA requests must be made 10 days in advance.
                      Your request will be reviewed. When approved, you will 
                      receive a confirmation.
                    </em></p>
                    <div className="p-grid">
                      {adaList.map(ada => (
                        <div key={ada.value} className="p-col p-lg-6 adarequest">
                          <Field
                            label={ada.label}
                            id={`custom_1247_${ada.value} `}
                            name={`custom_1247[${ada.value}]`}
                            component={Checkbox}
                          />
                        </div>
                      ))}
                      {/* <Field
                        id={`custom_1247`}
                        name={`custom_1247`}
                        options={adaList}
                        component={CheckboxGroup}
                        containerClass="p-col-6 adarequest"
                      /> */}
                      <div className="p-col-12">
                        <Field
                          label="Additional Details"
                          name="details" 
                          component={InputTextarea}
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              }
            </div>
          }
          {event.summary &&
            <div className="p-grid">
              <div className="p-col-12 event-summary">{event.summary}</div>
            </div>
          }
          <hr />
          {error && <p className="status-error">{error}</p>}
          <div className="p-grid p-justify-center p-align-center event-footer">
            {
              !['unavailable','past']
              .includes(rsvp_status) &&
              <div className="p-md-6">
                {
                  ['registered','waitlisted','pendingapproval','pendingattended']
                  .includes(rsvp_status) ?
                    <Button
                      type="button"
                      label="Cancel RSVP"
                      className="p-button-warning"
                      onClick={() => {
                        toggleCancel(true, event, () => {
                          toggleCancel(false);
                          handleSubmitForm();
                        })
                      }}
                      />
                  :
                    <Button
                      type="button"
                      label="Click here to RSVP"
                      className="p-button-success"
                      onClick={() => {
                        handleSubmitForm();
                      }}
                      />
                  }
              </div>
            }
            <div className="p-md-6">
              <Button
                type="button"
                label="Close"
                onClick={() => {
                  reset();
                  toggle(false);
                }}
              />
            </div>
          </div>
        </Form>
      </Dialog>
    )
  }
}

const saveName = 'register';
const formName = 'eventregister';

const mapStateToProps = (state) => {

  const { auth: { contact, contactId }, form } = state;
  const loaded = formName in form && 'values' in form[formName];
  
  return {
    contact,
    contactId,
    data: loaded && form[formName].values,
    loaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleEventRegister: event => dispatch(eventRegister(saveName, event)),
    handleSubmitForm: () => dispatch(submit(formName)),
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(EventDetails)
);