import constants from './constants';
import * as moment from 'moment-timezone';
import { merge } from 'lodash-es';

// console.log(process.env);
// console.log(window.location.hostname, window.location.hostname.match('seniorsdev.lalgbtcenter.org'))
// console.log(moment().tz('America/Los_Angeles').startOf('day').toString());

export const defaultState = {
  auth: {
    isFetching: false,
    testMode: process.env.NODE_ENV === "development" || window.location.hostname.match(process.env.REACT_APP_DEV_HOSTNAME)!==null,
    loggedIn: false,
    contactId: null,
    contact: null,
    weho: null,
    apiKey: '',
    defaultApiKey: process.env.REACT_APP_CIVICRM_DEFAULT_API_KEY, // this is the api key to very unprivliged user
    loggedInApiKey: null,
    siteKey: process.env.REACT_APP_CIVICRM_SITE_KEY,
    siteURL: '',
    siteURLDev: process.env.REACT_APP_DEV_URL,
    siteURLLive: process.env.REACT_APP_LIVE_URL,
  },
  display: {
    width: 0,
    height: 0,
    mobileMode: true,
    menuOpen: false,
  },
  load: {
    setup: false,
    statusMap: {},
  },
  list: {
    rsvp: {
      isFetching: false,
      data: [],
      count: null,
      sortBy: [
        { columnName: 'event_start_date', direction: 'asc' },
      ],
      date: null, // moment().tz('America/Los_Angeles').startOf('week').add(1, 'day').toISOString(), // start on monday
      specialValue: false,
      startDate: moment().tz('America/Los_Angeles').startOf('day').toISOString(),
      endDate: moment().tz('America/Los_Angeles').startOf('day').add(1, 'week').toISOString(),
      searchValue: '',
      statuses: [],
      locations: [],
      currentPage: 0,
      pageSize: 0,
      listState: '',
      loadingListState: '',
    },
    casemanagement: {
      isFetching: false,
      data: [],
      count: null,
      sortBy: [
        { columnName: 'activity_date_time', direction: 'asc' },
      ],
      searchValue: '',
      currentPage: 0,
      pageSize: 0,
      listState: '',
      loadingListState: '',
    },
    casemgmt_unavailable: {
      isFetching: false,
      data: [],
      count: null,
      startDate: moment().tz('America/Los_Angeles').startOf('day').toISOString(),
      endDate: moment().tz('America/Los_Angeles').endOf('day').toISOString(),
      sortBy: [],
      currentPage: 0,
      pageSize: 0,
      listState: '',
      loadingListState: '',
    },
  },
  save: {
    register: {
      isFetching: false,
    },
  },
  single: {
    account: {
      isFetching: false,
      data: {},
      state: '',
      loadingState: '',
    },
    dataenrichment: {
      isFetching: false,
      data: {},
      state: '',
      loadingState: '',
    },
  },
  messages: {
    items: [
      // {
      //   uuid: '306a010e-1350-478f-b664-2c8524a169a4',
      //   summary: 'title',
      //   detail: 'longer desc',
      //   severity: 'info',
      // },
    ],
  },
}

let initialState = {...defaultState};

const storedStateJSON = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
if (storedStateJSON!==null) {
  try {

    const storedState = JSON.parse(storedStateJSON);
    initialState = merge({}, initialState, storedState);

  } catch (e) {
    // wasn't json i guess, no worries
  }
}

export default initialState;