import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

class ConfirmCancel extends Component {

  render() {

    const { open, toggle, event, onContinue } = this.props;

    if (!event)
      return null;

     const { event_title } = event;

    return (
      <Dialog 
        className="ConfirmCancel"
        visible={open}
        onHide={() => toggle(false)}
        header="RSVP Cancellation"
      >
        <p>Are you sure you want to cancel your RSVP for "{event_title}"?</p>
        <div className="p-grid p-justify-center">
          <div className="p-col-fixed">
            <Button onClick={() => toggle(false)} label="No" />
          </div>
          <div className="p-col-fixed">&nbsp;</div>
          <div className="p-col-fixed">
            <Button onClick={onContinue} label="Yes" className="p-button-warning" />
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCancel);