import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  // Field, 
  Form,
  reduxForm,
} from 'redux-form';
// import { Button } from 'primereact/button';
// import { Calendar } from 'components/FormFields';
// import { Dropdown } from 'components/FormFields';
// import { InputTextarea } from 'components/FormFields';
import * as moment from 'moment-timezone';
// import locationsList from 'helpers/Filter/locations';

class ParkingRequest extends Component {

  componentDidMount() {
    this.props.initialize({
      date: moment().tz('America/Los_Angeles').add(3, 'day').startOf('day').toDate(),
      time: moment().tz('America/Los_Angeles').set({ hour: 9, minute: 0, second: 0 }).toDate(),
    })
  }

  render() {

    const { handleSubmit } = this.props;

    return (
      <div className="ParkingRequest">
        <Form onSubmit={handleSubmit}>
          <h3>Coming soon!</h3>
          {/* <Field
            label="Date"
            name="activity_date_time" 
            // disabled={isFetching}
            showTime={false}
            component={Calendar}
            touchUI={true}
            readOnlyInput={true}
            showIcon={true}
            // minDate={moment().tz('America/Los_Angeles').add(1, 'day').startOf('day').toDate()}
          />
          <Field
            label="Appointment Location"
            name="location" 
            component={Dropdown}
            options={locationsList}
          />
          <Field
            label="Details"
            name="details" 
            component={InputTextarea}
          />
          <Button type="submit" label="Submit Request" /> */}
        </Form>
      </div>
    );
  }
}

const formName = 'parkingrequest';

const validate = val => {
  const errors = {};

  const minDate = moment().tz('America/Los_Angeles').add(1, 'day').startOf('day');

  if (!val.activity_date_time) 
    errors.activity_date_time = 'Required';
  else if (val.activity_date_time instanceof Date && moment(val.activity_date_time.toISOString()).isBefore(minDate))
    errors.activity_date_time = 'Cannot schedule in past'
 
  if (!val.location)
    errors.location = 'Required';

  if (val.details && val.details.length>280) 
    errors.details = 'Comments are too long';

  return errors;
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: async data => {
      console.log(data);
      alert('Submitting!');
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    validate,
  })(ParkingRequest)
);