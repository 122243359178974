import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import { InputText } from 'components/FormFields';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { Button } from 'primereact/button';
import cx from 'classnames';
import './ForgotPassword/styles.css';

class ForgotPassword extends Component {

  submitForm = values => {

    const { handleMessage, reset } = this.props;
    
    /**
     * Validate
     */

    const errors = {};

    // Account info

    if (!values.username_or_email) 
      errors.username_or_email = 'Required';

    const errorKeys = Object.keys(errors);
    if (errorKeys.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError(errors);
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    /**
     * Save
     */

    const json = {
      username_or_email: values.username_or_email,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));

    const url = handleCiviURLBuild('Account', 'recover');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Please check your email for an account recovery email', 'success');
      reset();
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue recovering your account (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { handleSubmit, mobileMode, pristine, submitting } = this.props;

    return (
      <div className="ForgotPassword">
        <div className="p-grid gridContainer">
          <div className ="p-md-4 hasBackground">
            <div className ={cx({ 'droppedCol': !mobileMode })}>
              <h1>Senior Services</h1>
              <p>
                Use this portal to register for 
                events and case management appointments.
              </p>
            </div>
          </div>
          <div className ="p-md-8">
            <div className="fieldContainer">
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
              <hr />
              <Form onSubmit={handleSubmit(this.submitForm)}>
                <h2>Recover My Account</h2>
                <Field
                  label="Username or Email"
                  name="username_or_email" 
                  // disabled={isFetching}
                  component={InputText}
                />
                <div className="form-group">
                  <Button type="submit" className="button" label="Recover Account" disabled={pristine || submitting} />
                </div>
                <p>Your username is generally the first four characters of your first name followed by the two digit representation of the month and day of your birth date. For example, James Smith born on February 9th, 1950 would have a username: jame0209</p>
              </Form>
              <hr />
              <Link to="/login">&lt;&lt; Back to Sign In</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const formName = 'forgotpasswword';

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(ForgotPassword)
);