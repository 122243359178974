import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menuOpen } from 'store/actions/display';
import logo from 'assets/images/logohoz-white.png';
import './Header/styles.css';

class Header extends Component {

  render() {

    const { handleMenuOpen, loggedIn, menuOpen, mobileMode, testMode } = this.props;

    return (
      <div className="Header" style={ testMode ? { background: '#000' } : {} }>
        <div className="p-grid p-nogutter p-align-center">
          <div className="p-col">
            <img src={logo} className="logo" alt="Los Angeles LGBT Center Logo" />
          </div>
          {loggedIn && mobileMode && 
            <div className="p-col-fixed textCenter" style={{ width: 50 }}>
              <i className="mobileMenu pi pi-bars" onClick={() => handleMenuOpen(!menuOpen)}></i>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { contact, loggedIn, testMode } = state.auth;
  const { menuOpen, mobileMode } = state.display;
  return {
    contact,
    loggedIn,
    menuOpen,
    mobileMode,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMenuOpen: open => dispatch(menuOpen(open)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);