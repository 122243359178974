import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCurrentListState, updateLoadingListState, setListData } from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import './CaseManagement/styles.css';
import * as moment from 'moment-timezone';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
// import Map from './App/Map';
import TitleBar from './App/TitleBar';
import ScheduleAppointment from './CaseManagement/ScheduleAppointment';
import UpcomingAppointments from './CaseManagement/UpcomingAppointments';
import locationsList from 'helpers/Filter/locations';

class CaseManagement extends Component {

  componentDidMount() {
    this.loadData();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.searchValue,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
    ].join('|');
    return listState;
  }
  
  loadData = () => {

    const currentListState = this.calculateCurrentListState();
    const { listState, loadingListState } = this.props;
    // console.log({currentListState, listState, loadingListState})
    if (
      currentListState === listState ||
      currentListState === loadingListState
    ) return;

    const { 
      contactId,
      sortBy,
      updateLoadingListState,
      updateCurrentListState,
      setListData,
      handleMessage,
    } = this.props;

    updateLoadingListState(currentListState);

    const returnFields = [
      'activity_date_time',
      'subject',
      'duration',
      'status_id',
      'custom_172', // activity location
      'custom_173', // off site location
      'custom_174', // off site address
    ];
    
    const url = handleCiviURLBuild('Request','get',{
      sequential:1,
      activity_type_id: '163', // case management walk in request
      activity_date_time: {
        '>=': moment().tz('America/Los_Angeles').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
      target_contact_id: contactId,
      return: returnFields,
      options:{
        sort: sortBy.map(v => v.columnName + ' ' + v.direction).join(', '),
        limit :0,
      },
    });
    // console.log(url);

    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json.values;
    })
    .then(data => data.map(activity => {
      
      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in activity))
          activity[field] = '';
      });

      const { activity_date_time, custom_172, custom_173, custom_174 } = activity;

      let activityLocation = locationsList.find(location => location.value===custom_172);

      // parse states for display
      activity.date_display = moment.tz(activity_date_time, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMM D, h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.');
      // parse activity for display
      activity.activity_location_value = activityLocation ? activityLocation.value : null;
      activity.activity_location = activityLocation && activityLocation.value!=='Other' ? 
        activityLocation.label : 
        ( custom_173.length>0 ? custom_173 /* + (custom_174.length>0 ? ' (' + custom_174 + ')' : '') */ : '' );
      activity.activity_location_address = custom_174;
      
      return activity;
    }))
    .then(data => {
      updateCurrentListState(currentListState);
      setListData({data});
      updateLoadingListState('');
    })
    .catch(e => {
      updateCurrentListState(currentListState);
      handleMessage(e.message, 'error');
      updateLoadingListState('');
    });
  }

  render() {

    return (
      <div className="CaseManagement">
        <Route component={TitleBar} />
        <div className="mainArea">
          <div className="p-grid">
            <div className="p-col p-lg-6 boxed">
              <fieldset>
                <legend>Schedule Case Management Appointment</legend>
                <h3>Thank you for contacting us. Due to Covid-19 and changes in programming at Senior Services, this function is currently disabled. If you would like to make an appointment with a Case Manager, please contact Senior Services directly at 323-860-5830. Your request will be sent to the program manager.</h3>
                {/* <ScheduleAppointment /> */}
              </fieldset>
            </div>
            <div className="p-col p-lg-6 boxed">
              <fieldset>
                <legend>Upcoming Case Management Appointment</legend>  
                <UpcomingAppointments />
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const listName = 'casemanagement';

const mapStateToProps = (state) => {

  const { 
    auth: { contact, contactId },
    display: { mobileMode }, 
    list,
  } = state;

  return {
    contact,
    contactId,
    ...list[listName],
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentListState: state => dispatch(updateCurrentListState(listName, state)),
    updateLoadingListState: state => dispatch(updateLoadingListState(listName, state)),
    setListData: data => dispatch(setListData(listName, data)),
    handleMessage: (msg, severity='info') => dispatch(addMessage(msg, severity)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CaseManagement);