import constants from '../constants';
import {default as uuid} from "uuid";

export const addMessage = (summary, severity='info', detail='') => ({
  type: constants.ADD_MESSAGE,
  value: {
    uuid: uuid.v4(),
    summary,
    severity,
    detail,
  },
})

export const clearMessages = (uuid=null) => {
  if (uuid===null) {
    return {
      type: constants.CLEAR_ALL_MESSAGES,
    };
  }
  return {
    type: constants.CLEAR_SINGLE_MESSAGE,
    value: uuid,
  };
}