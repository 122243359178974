import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCurrentListState, updateLoadingListState, setListData } from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { 
  dateDisplay,
  rsvpStatus,
  rsvpStatusForFilter,
} from 'helpers/CiviCRM';
import './RSVP/styles.css';
import * as moment from 'moment-timezone';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import DateSelect from './RSVP/DateSelect';
import EventList from './RSVP/EventList';
import Filter from './RSVP/Filter';
// import Map from './App/Map'; // full map functionality
import EventListMap from './RSVP/EventListMap'; // click location on event list
import TitleBar from './App/TitleBar';
import locationsList from 'helpers/Filter/locations';
import roomsList from 'helpers/Filter/rooms';

class RSVP extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      location: null,
    }
  }

  componentDidMount() {
    this.loadData();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.date,
      this.props.startDate,
      this.props.endDate,
      this.props.searchValue,
      this.props.specialValue,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      // this.props.locations.join(','),
      // this.props.statuses.join(','),
    ].join('|');
    return listState;
  }
  
  loadData = () => {

    const currentListState = this.calculateCurrentListState();
    const { listState, loadingListState } = this.props;
    // console.log({currentListState, listState, loadingListState})
    if (
      currentListState === listState ||
      currentListState === loadingListState
    ) return;

    const { 
      searchValue, 
      specialValue, 
      date, 
      startDate, 
      endDate, 
      sortBy,
      // locations, 
      // statues,
      updateLoadingListState,
      updateCurrentListState,
      setListData,
      handleMessage,
      statusMap,
    } = this.props;

    updateLoadingListState(currentListState);

    let start, end;

    if (date===null) {
      start = moment(startDate).startOf('day');
      end = moment(endDate).endOf('day');
    } else {
      const momentDate = moment(date).tz('America/Los_Angeles');
      start = momentDate.clone().startOf('day');
      end = momentDate.clone().endOf('day');
    }

    const now = moment().tz('America/Los_Angeles');
    if (start.isBefore(now))
      start = now.clone();

    const endOfMonth = moment().tz('America/Los_Angeles').endOf('month');
    if (end.isAfter(endOfMonth))
      end = endOfMonth.clone();

    const positiveStatues = Object.keys(statusMap).map(i => statusMap[i]).filter(i => i.class==="Positive").map(v => v.id);

    const returnFields = [
      "event_title",
      "summary",
      "description",
      "event_type_id",
      "event_type",
      "event_start_date",
      "event_end_date",
      "is_online_registration",
      "is_full",
      "available_seats",
      "max_participants",
      "custom_1198", // Event Location
      "custom_1205", // Other Event Location
      "custom_1206", // Other Event Location Address
      "custom_1246", // Drop In Allowed
      "custom_1248", // closed group
      "custom_1305", // Room / Location
    ];

    const json = {
      sequential:1,
      event_title: {
        LIKE: '%' + searchValue + '%',
      },
      start_date: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      },
      // end_date: {
      //   BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      // },
      return: returnFields,
      'api.Participant.get': {
        sequential: 1,
        event_id: "$value.id",
        contact_id: "user_contact_id",
      },
      'api.Participant.getcount': {
        event_id: "$value.id",
        'status_id': positiveStatues,
        'options': {
          'offset': 0,
          'limit': 0,
        },
      },
      options:{
        // or: [
        //   ["start_date","end_date"]
        // ],
        sort: sortBy.map(v => v.columnName + ' ' + v.direction).join(', '),
        limit: 0,
      },
    };
    
    if (specialValue) {
      json['start_date'] = {
        '>': start.format('YYYY-MM-DD HH:mm:ss'), // TODO check compairson operator with Civi Dev tools
      };
      json['custom_1475'] = 1;
    };

    const url = handleCiviURLBuild('PublicEvent', 'get', json);
    // console.log(url);

    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json.values;
    })
    .then(data => data.map(event => {

      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in event))
          event[field] = '';
      });

      const { custom_1198, custom_1205, custom_1206, custom_1305 } = event;
      
      const eventLocation = locationsList.find(location => location.value===custom_1198);
      const roomLocation = roomsList.find(room => room.value===custom_1305);
      
      // parse states for display
      event.date_display = <div>{dateDisplay(event)}</div>;

      // rsvp status
      event.rsvp_status = rsvpStatus(event, statusMap);
      event.rsvp_status_orig = rsvpStatus(event, statusMap, true);
      event.rsvp_status_filter = rsvpStatusForFilter(event.rsvp_status);
      
      // parse event_location for display
      event.event_location_value = eventLocation ? eventLocation.value : null;
      event.event_location = eventLocation && eventLocation!=='Other' ? 
        eventLocation.label : 
        ( custom_1205.length>0 ? custom_1205 /* + (custom_1206.length>0 ? ' (' + custom_1206 + ')' : '') */ : '' );
      event.event_location_address = custom_1206;

      // room number
      event.room_location_value = roomLocation ? roomLocation.value : null;
      event.room_location = roomLocation ? roomLocation.label : null;
      
      return event;
    }))
    .then(data => {
      updateCurrentListState(currentListState);
      setListData({data});
      updateLoadingListState('');
    })
    .catch(e => {
      updateCurrentListState(currentListState);
      handleMessage(e.message, 'error');
      updateLoadingListState('');
    });
  }

  setMapLocation = location => this.setState({ location })

  render() {

    const { location } = this.state;

    return (
      <div className="RSVP">
        <Route component={TitleBar} />
        <div className="mainArea">
          <DateSelect />
          <Filter />
          <EventList setLocation={this.setMapLocation} />
        </div>
        {/* full map */}
        {/* <Map /> */}
        {/* event list map */}
        <EventListMap location={location} setLocation={this.setMapLocation} />
      </div>
    )
  }
}

const listName = 'rsvp';

const mapStateToProps = (state) => {
  const list = state.list[listName];
  const { statusMap } = state.load;
  return {
    ...list,
    statusMap,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentListState: state => dispatch(updateCurrentListState(listName, state)),
    updateLoadingListState: state => dispatch(updateLoadingListState(listName, state)),
    setListData: data => dispatch(setListData(listName, data)),
    handleMessage: (msg, severity='info') => dispatch(addMessage(msg, severity)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(RSVP);