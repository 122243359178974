import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import FAQ from './FAQ';

class FAQDialog extends Component {

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    return (
      <Dialog 
        className="FAQDialog"
        visible={open}
        maximizable={true}
        onHide={() => toggle(false)}
        header="FAQ"
      >
        <FAQ />
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FAQDialog);