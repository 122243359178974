import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { setSingleData, updateCurrentState, updateLoadingState } from 'store/actions/single';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import './Account/styles.css';
import { Button } from 'primereact/button';
// import Map from './App/Map';
import UsernamePassword from './Account/UsernamePassword';
import Demographics from './Account/Demographics';
import EmergencyContact from './Account/EmergencyContact';
import Preferences from './Account/Preferences';
import Profile from './Account/Profile';
import TitleBar from './App/TitleBar';
import * as moment from 'moment-timezone';

class Account extends Component {

  componentDidMount() {
    /**
     * reinitialize forms on load if we already know the data, otherwise load data from CiviCRM
     */    
    const currentState = this.calculateCurrentListState();
    const { state } = this.props;
    if (state === currentState) {
      const { data } = this.props;
      this.props.initializeAccount(data);
    } else {
      this.loadData();
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.contactId,
    ].join('|');
    return listState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentListState();
    const { state, loadingState } = this.props;
    // console.log({ currentState, state, loadingState })
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    const { 
      contactId,
      handleUpdateLoadingState,
      handleUpdateCurrentState,
      handleSetSingleData,
      handleMessage,
    } = this.props;

    if (typeof state === 'undefined' && typeof loadingState === 'undefined')
      return;
    // return;

    handleUpdateLoadingState(currentState);

    const url = handleCiviURLBuild('Account','get',{
      sequential: 1,
      id: contactId,
      return: [
        // stuff we rely on in other places
        'display_name',
        'image_URL',
        // stuff we specifically need
        'first_name',
        'last_name',
        'street_address',
        'supplemental_address_1',
        'city',
        'state_province',
        'postal_code',
        // 'location_type_id', // doesn't get returned
        'phone',
        'phone_type_id',
        'email',
        'do_not_phone',
        'do_not_email',
        'do_not_mail',
        'do_not_sms',
        'preferred_communication_method',
        'birth_date',
        'custom_127', // emergency contact name
        'custom_128', // emergency contact phone
        'custom_170', // emergency contact relationship
        'custom_1213', // disabled
        'custom_115', // veteran
        'gender_id',
        'custom_1215', // transgender
        'custom_113', // sexual orientation
        'custom_1212', // race
        'custom_1214', // homeless
        'custom_1216', // How many in household
        'custom_1217', // annual income
        'custom_1451', // religion/spiritual practice
      ],
      types: [
        // 'pdf',
        // 'html',
        // 'raw',
        'links',
      ],
    });
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);

      handleUpdateCurrentState(currentState);

      const account = {...json.values[0]};

      // parse out some check boxes to bools
      account.contact.do_not_phone = account.contact.do_not_phone==='1';
      account.contact.do_not_email = account.contact.do_not_email==='1';
      account.contact.do_not_mail = account.contact.do_not_mail==='1';
      account.contact.do_not_sms = account.contact.do_not_sms==='1';

      const { preferred_communication_method } = account.contact;
      account.contact.preferred_communication_method = [
        null,
        preferred_communication_method.includes('1'),
        preferred_communication_method.includes('2'),
        preferred_communication_method.includes('3'),
        preferred_communication_method.includes('4'),
      ];

      handleSetSingleData(json.values[0]);
      this.props.initializeAccount(account);

      return account;
    })
    .then(accont => handleUpdateLoadingState(''))
    .catch((e) => {
      handleUpdateCurrentState(currentState);
      handleMessage(e.message, 'error');
      handleUpdateLoadingState('');
    });
  }

  submitForm = values => {

    const { handleMessage, handleUpdateCurrentState } = this.props;
    
    const auth = {...values.auth};
    const contact = {...values.contact};
    const { contact_image } = values;

    /**
     * Validate
     */

    const authErrors = {};
    const contactErrors = {};

    // Account info

    if (!auth.username) 
      authErrors.username = 'Required';

    if (auth.password) {
      if (
        auth.password.length<8 ||
        !auth.password.match(/[a-z]+/) ||
        !auth.password.match(/[A-Z]+/) ||
        !auth.password.match(/[0-9]+/)
      )
        authErrors.password = 'Does not match requirements';

      if (!auth.password_confirmation) 
        authErrors.password_confirmation = 'Required';

      if (auth.password!==auth.password_confirmation)
        authErrors.password_confirmation = 'Passwords must match';
    }

    // Profile

    if (!contact.first_name)
      contactErrors.first_name = 'Required';
  
    if (!contact.last_name)
      contactErrors.last_name = 'Required';
  
    if (!contact.street_address)
      contactErrors.street_address = 'Required';
  
    if (!contact.city)
      contactErrors.city = 'Required';
    
    if (!contact.state_province_id)
      contactErrors.state_province_id = 'Required';
    
    if (!contact.postal_code)
      contactErrors.postal_code = 'Required';
  
    if (!contact.phone)
      contactErrors.phone = 'Required';
    else if (!contact.phone.match(/^\(\d{3}\)\s\d{3}-\d{4}$/))
      contactErrors.phone = 'Invalid phone';

    if (!contact.phone_type_id)
      contactErrors.phone_type_id = 'Required';
  
    if (!contact.email)
      contactErrors.email = 'Required';
    else if (contact.email.indexOf('@')<0)
      contactErrors.email = 'Invalid email';
  
    if (!contact.birth_date)
      contactErrors.birth_date = 'Required';
  
    if (!contact.custom_1213) // diabled
      contactErrors.custom_1213 = 'Required';
  
    if (!contact.custom_115) // veteran
      contactErrors.custom_115 = 'Required';
  
    if (!contact.gender_id)
      contactErrors.gender_id = 'Required';
  
    if (!contact.custom_1215) // transgender
      contactErrors.custom_1215 = 'Required';
  
    if (!contact.custom_113) // sexual orientation
      contactErrors.custom_113 = 'Required';
  
    if (!contact.custom_1212 || (Array.isArray(contact.custom_1212) && contact.custom_1212.length===0)) // race
      contactErrors.custom_1212 = 'Required';
  
    if (!contact.custom_1214) // homeless
      contactErrors.custom_1214 = 'Required';

    /**
     * Aggrigate errors
     */
    const authErrorKeys = Object.keys(authErrors);
    const contactErrorsKeys = Object.keys(contactErrors);
    if (authErrorKeys.length>0 || contactErrorsKeys.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        auth: authErrors,
        contact: contactErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    /**
     * Modify some data that needed handled differently in checkboxes
     */

    contact.do_not_phone = contact.do_not_phone ? '1' : '0';
    contact.do_not_email = contact.do_not_email ? '1' : '0';
    contact.do_not_mail = contact.do_not_mail ? '1' : '0';
    contact.do_not_sms = contact.do_not_sms ? '1' : '0';

    const { preferred_communication_method } = contact;
    contact.preferred_communication_method = preferred_communication_method ? preferred_communication_method.map((v,i) => v ? i : false).filter(v => v!==false) : [];

    const birth_date_local = moment(contact.birth_date); // consume in in local timezone
    const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
    contact.birth_date = birth_date.format('YYYY-MM-DD');

    /**
     * Save
     */

    const json = {
      auth,
      contact,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));

    if (contact_image && contact_image[0]) {
      formData.append('contact_image', contact_image[0]);
    }
  
    const url = handleCiviURLBuild('Account', 'create');

    return fetch(url, {
      method: "POST",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Account information saved', 'success');
      handleUpdateCurrentState(''); // refresh this page
      return json;
    })
    .catch(error => {
      handleMessage(`There was an issue saving your account information (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <div className="Account">
        <Route component={TitleBar} />
        <div className="mainArea">
          <Form onSubmit={handleSubmit(this.submitForm)}>
            <p><em>If you have difficulty updating your profile information, please contact Senior Services desk.</em></p>
            <div className="p-grid">
              <div className="p-col p-lg-4 boxed">
                <fieldset>
                  <legend>My Account</legend>
                  <UsernamePassword {...this.props} />
                </fieldset>
              </div>
              <div className="p-col p-lg-8 boxed">
                <fieldset>
                  <legend>My Profile</legend>
                  <Profile {...this.props} />
                </fieldset>
              </div>
              <div className="p-col p-col-12 boxed">
                <fieldset>
                  <legend>Emergency Contact</legend>
                  <EmergencyContact {...this.props} />
                </fieldset>
              </div>
              <div className="p-col p-col-12 boxed">
                <fieldset>
                  <legend>My Preferences</legend>
                  <Preferences {...this.props} />
                </fieldset>
              </div>
              <div className="p-col p-col-12 boxed">
                <fieldset>
                  <legend>Demographic Information</legend>
                  <p><em>
                    Information provided to senior services will remain confidential. 
                    The complete and accurate information you provide, assists us in 
                    maintaining and expanding our free and low cost services.  We 
                    appreciate you fully completing the profile information requested.
                  </em></p>
                  <Demographics {...this.props} />
                </fieldset>
              </div>
            </div>
            <div className="form-group">
              <Button type="submit" className="button" label="Update Account" disabled={pristine || submitting} />
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

const singleName = 'account';
const formName = 'account';

const mapStateToProps = (state) => {
  
  const { auth: { contactId }, form } = state;
  
  const single = state.single[singleName];

  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    contactId,
    ...single,
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),

    handleUpdateLoadingState: state => dispatch(updateLoadingState(singleName, state)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState(singleName, state)),
    handleSetSingleData: data => dispatch(setSingleData(singleName, data)),

    initializeAccount: data => dispatch(initialize(formName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(Account)
);