import React from 'react';
import store from 'store';
import activityStatuesesList from './Filter/activityStatuses';
import statusesList from './Filter/statuses';
import * as moment from 'moment-timezone';

export const URLBuild = (entity, action, obj = null, apiKey = null) => {

  const state = store.getState();
  const { siteURL, siteKey } = state.auth;

  apiKey = apiKey ?? state.auth.apiKey;

  const url = siteURL+'?'+
    'entity='+encodeURIComponent(entity)+'&'+
    'action='+encodeURIComponent(action)+'&'+
    'key='+encodeURIComponent(siteKey)+'&'+
    'api_key='+encodeURIComponent(apiKey)+'&'+
    'version=3&'+
    (obj === null ? '' : 'json='+encodeURIComponent(
      JSON.stringify(obj)
    )+'&')+
  '';
  return url;
}

/**
 * converts a civicrm event with participation object into a string of how we want to handle it
 */
export const rsvpStatus = (event, statusMap, originalStatus = false) => {

	/**
	 * Current Event status totals as for 12/28/2018
	 * --------------------
	 * select status_id, pst.label, count(*) 
	 * from civicrm_participant p 
	 * join civicrm_participant_status_type pst 
	 * 	on p.status_id=pst.id 
	 * group by status_id 
	 * order by count(*) desc
	 * --------------------
	 * 2	Attended	66053
	 * 3	No-show	5479
	 * 18	Dropped In	3836
	 * 16	Confirmed	1444 -- registered
	 * 4	Cancelled	1130
	 * 17	Reminded	1101 -- registered
	 * 1	Registered	922 -- registered
	 * 7	Waitlisted	357 -- waitlisted
	 * 23	Waitlist - Emailed	177 -- waitlisted
	 * 19	Paid	151 -- registered
	 * 12	RSVP Deadline Has Expired	66
	 * 22	Waitlist - Called	21 -- waitlisted
	 * 6	Pending (incomplete transaction)	15
	 * 20	Seniors Helping Seniors (SHS)	12
	 */

	const { custom_1246, is_online_registration, is_full, start_date } = event;

	const momentNow = moment().tz('America/Los_Angeles').startOf('day');
	const momentStartDate = moment.tz(start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");

	/**
	 * Past events, Just display a status
	 */
	if (momentStartDate.isBefore(momentNow)) {
		return 'past';
	}

	// console.log(event['api.Participant.get'].values.length, event)

	/**
	 * Not yet registred
	 */
  if (event['api.Participant.get'].values.length===0 || originalStatus) {
		if (custom_1246==='1' || custom_1246==='3') {
			return 'dropin';
		}
		if (is_online_registration==='1') { // drop in or online registration
			if (is_full==="1")
				return "waitlist";
			else
				return 'rsvp';
		}
		return 'unavailable';
	}

	/**
	 * Already Registered
	 */

	const participation = event['api.Participant.get'].values[0];

	const status_id = 'status_id' in participation ? participation.status_id : participation.participant_status_id;
	
	// we don't have this value in our status map
	if (!(status_id in statusMap))
		return 'unavailable';

	// get the status
	const status = statusMap[status_id];

	// currently registered
	if (['1','16','17','19'].includes(status.id))
		return 'registered';

	// currently waitlisted
	if (['7', '23', '21'].includes(status.id))
		return 'waitlisted';

	if (['8'].includes(status.id))
		return 'pendingapproval';

	if (['25'].includes(status.id))
		return 'pendingattended';

	if (['4', '24'].includes(status.id))
		return 'cancelled';
	
	return 'unavailable';
}

/**
 * simplifies the rsvp status into option that match the filter
 */
export const rsvpStatusForFilter = rsvp_status => {
	switch (rsvp_status) {
		case 'dropin': 
		case 'waitlist': 
		case 'rsvp': {
			return 'rsvp';
		}
		case 'registered':
		case 'pendingattended': {
			return 'confirmed';
		}
		case 'waitlisted': {
			return 'waitlisted';
		}
		case 'pendingapproval': {
			return 'rsvp_pending';
		}
		case 'cancelled': {
			return 'cancelled';
		}
		default: {
			return 'unavailable';
		}
	}
}

/**
 * create a display and click fucntion for each status
 */
export const RSVPStatusDisplay = props => {

	const { onClick, rsvp_status } = props;

	if (rsvp_status==='past')
		return <div onClick={onClick ? onClick : () => null}>Completed</div>;

	switch (rsvp_status) {

		case 'dropin': {
			const { label, color } = statusesList.find(status => status.value === 'rsvp');
			return rsvpStatusChip(
				label,
				color,
				onClick
			);
		}

		case 'waitlist': {
			const { label, color } = statusesList.find(status => status.value === 'rsvp');
			return rsvpStatusChip(
				label + ' (Waitlist)',
				color,
				onClick
			);
		}

		case 'rsvp': 
		case 'cancelled': {
			const { label, color } = statusesList.find(status => status.value === 'rsvp');
			return rsvpStatusChip(
				label,
				color,
				onClick
			);
		}

		case 'registered':
		case 'pendingattended': {
			const { label, color } = statusesList.find(status => status.value === 'confirmed');
			return rsvpStatusChip(
				label,
				color,
				onClick
			);
		}

		case 'waitlisted': {
			const { label, color } = statusesList.find(status => status.value === 'waitlisted');
			return rsvpStatusChip(
				label,
				color,
				onClick
			);
		}

		case 'pendingapproval': {
			const { label, color } = statusesList.find(status => status.value === 'rsvp_pending');
			return rsvpStatusChip(
				label,
				color,
				onClick
			);
		}

		// case 'cancelled': {
		// 	const { label, color } = statusesList.find(status => status.value === 'cancelled');
		// 	return rsvpStatusChip(
		// 		label,
		// 		color,
		// 		onClick
		// 	);
		// }

		default: {
			// just rsvp_status in ['unavailable','pendingattended'] should fall through to here
			const { label, color } = statusesList.find(status => status.value === 'unavailable');
			return rsvpStatusChip(label, color, onClick);
		}

	}
	
}

export const RequestStatusDisplay = props => {

	const { onClick, activity } = props;
	const { status_id } = activity;
	
	const activityStatus = activityStatuesesList.find(status => status.value === status_id);

	if (activityStatus !== undefined) {
		const { label, color } = activityStatus;
		return rsvpStatusChip(
			label,
			color,
			onClick
		);
	}

	return rsvpStatusChip(
		'Unknown',
		'#777',
	);	
}

/**
 * display template for rsvp statuses
 */
const rsvpStatusChip = (label, color='#ccc', onClick=null) => {
	return (
		<div 
			style={{ 
				padding: '0.25rem',
				background: color,
				color: '#fff',
				textAlign: 'center',
				cursor: onClick ? 'pointer' : 'default',
			}}
			onClick={onClick===null ? () => null : onClick}
		>
			{label}
		</div>
	)
}

export const dateDisplay = event => {
	const start_date = moment.tz(event.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	const end_date = event.event_end_date===null || event.event_end_date==='' ? 
		null : 
		moment.tz(event.event_end_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	// console.log('start_date', event.start_date, start_date);
	// console.log('end_date', event.end_date, end_date);

	const start_date_time_format = 
		(start_date.format('mm')==='00' ? 'h' : 'h:mm') +
		(end_date && end_date!==null && start_date.format('a')!==end_date.format('a') ? ' a' : '');
	const end_date_time_format = 
		end_date && end_date!==null ? 
		(end_date.format('mm')==='00' ? 'h a' : 'h:mm a') : 
		'';

	let date_display = '-- unknown date --';
	if (end_date === null) { // no end date
		date_display = start_date.format('MMM D, ' + start_date_time_format);
	} else if (start_date.format('YYYY-MM-DD HH:mm') === end_date.format('YYYY-MM-DD HH:mm')) { // same start and end date/time
		date_display = start_date.format('MMM D, ' + start_date_time_format);
	} else if (start_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) { // same start and end date, different time
		date_display = start_date.format('MMM D, ' + start_date_time_format) + ' - ' + end_date.format(end_date_time_format);
	} else { // different start and end dates
		date_display = start_date.format('MMM D') + ' - ' + end_date.format('MMM D');
	}

	return date_display.replace('am', 'a.m.').replace('pm', 'p.m.');
}

export const timeDisplay = event => {

	// console.log(event);
	let end_date = '';
	let end_date_moment = null
	if ('event_end_date' in event && event.event_end_date.length>0) {
		end_date_moment = moment.tz(event.event_end_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
		end_date = end_date_moment.format(end_date_moment.format('mm')==='00' ? 'h a' : 'h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.');
	}

	const start_date_moment = moment.tz(event.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	let start_date = start_date_moment.format('mm')==='00' ? start_date_moment.format('h') : start_date_moment.format('h:mm');
	if (!end_date || end_date_moment===null || start_date_moment.format('a') !== end_date_moment.format('a'))
		start_date += start_date_moment.format(' a').replace('am', 'a.m.').replace('pm', 'p.m.');

	let date_display = start_date;
	if (end_date)
		date_display += ' - ' + end_date;

	return date_display;
}