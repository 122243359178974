import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  // Marker,
} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

const MapComponent = withScriptjs(withGoogleMap((props) => {

  const { center, geoCodeAddress, handleCenterChange, handleZoomChange, marker, zoom } = props;

  const mapRef = React.createRef();

  // console.log(marker);

  if (marker.position===null) {
    const { google } = window;
    geoCodeAddress(google, marker);
  }

  return (
    <GoogleMap
      // defaultZoom={13}
      // defaultCenter={{ lat: 34.100552, lng: -118.332732 }}
      onZoomChanged={() => handleZoomChange(mapRef.current.getZoom())}
      onCenterChanged={() => handleCenterChange(mapRef.current.getCenter())}
      zoom={zoom}
      center={center}
      ref={mapRef}
    >
      {marker.position!==null &&
        <MarkerWithLabel 
          position={marker.position}
          // onClick={e => console.log(e)}
          labelAnchor={{ x: 0, y: 135 }}
          labelStyle={{
            fontSize: '24px',
            transform: 'translateX(-50%)',
            background: '#ccc',
            border: '1px solid #000',
            padding: '0.5rem',
            textAlign: 'center',
          }}
        >
          <div>
            {marker.label}
            <br />
            <small>{marker.address}</small>
          </div>
        </MarkerWithLabel>
      }
    </GoogleMap>
  );
}));

export default MapComponent;