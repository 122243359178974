
const caseManagementTimeOptions = [
  {
    label: '10-10:45',
    value: '10:00:00',
    duration: '45',
  },
  {
    label: '11-11:45',
    value: '11:00:00',
    duration: '45',
  },
  {
    label: '1-1:45',
    value: '13:00:00',
    duration: '45',
  },
  {
    label: '2-2:45',
    value: '14:00:00',
    duration: '45',
  },
  {
    label: '3-3:45',
    value: '15:00:00',
    duration: '45',
  },
  {
    label: '4-4:45',
    value: '16:00:00',
    duration: '45',
  },
  // {
  //   label: '23:59:59',
  //   value: '23:59:59',
  //   duration: '45',
  // },
];

export default caseManagementTimeOptions;