import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMessage } from 'store/actions/messages';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { setSingleData, updateCurrentState, updateLoadingState } from 'store/actions/single';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import './styles.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import WeHo from '../../Account/WeHo';
import * as moment from 'moment-timezone';

//~ In West Hollywood:
//~ 8261 Norton Avenue 90046
//~ 1245 N Genesee Ave 90046
//~ 866 Hilldale Ave 90046

//~ Outside West Hollywood:
//~ 7928 Hollywood Blvd, 90046
//~ 800 N Genesee Ave 90046

const initialState = {
  somethingMissing: false,
  showLivesWeHo: null,
  showWorksWeHo: null,
  showPropertyWeHo: null,
  showSchoolWeHo: null,
  showHomelessWeHo: null,
};

class DataEnrichmentWeHo extends Component {

  constructor(props) {
    super(props);
    this.state = {...initialState};
  }

  componentDidMount() {
    /**
     * reinitialize forms on load if we already know the data, otherwise load data from CiviCRM
     */    
    const currentState = this.calculateCurrentListState();
    const { state } = this.props;
    if (state === currentState) {
      const { data } = this.props;
      this.props.initializeDataEnrichment(data);
    } else {
      this.loadData();
    }
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadData();
  }
  
  calculateCurrentListState = () => {
    const listState = [
      this.props.contactId,
    ].join('|');
    return listState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentListState();
    const { state, loadingState, toggle } = this.props;
    // console.log({ currentState, state, loadingState })
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    const { 
      contactId,
      handleUpdateLoadingState,
      handleUpdateCurrentState,
      handleSetSingleData,
      handleMessage,
    } = this.props;

    if (typeof state === 'undefined' && typeof loadingState === 'undefined')
      return;
    // return;

    handleUpdateLoadingState(currentState);
    
    const url = handleCiviURLBuild('Account','get',{
      sequential: 1,
      id: contactId,
      return: [
        'custom_1464',
        'custom_1465',
        'custom_1466',
        'custom_1467',
        'custom_1468',
      ],
    });
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }, 
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);

      handleUpdateCurrentState(currentState);

      const account = {...json.values[0]};

      handleSetSingleData(json.values[0]);
      // this.props.initializeDataEnrichment(account);

      return account;
    })
    .then(account => {

      // const { contactId } = this.props;

      // calculate which weho items needs displayed
      let {
        showLivesWeHo,
        showWorksWeHo,
        showPropertyWeHo,
        showSchoolWeHo,
        showHomelessWeHo,
      } = this.state;

      const { contact } = account;
      let somethingMissing = false;
      const updateDate = moment().tz('America/Los_Angeles').subtract(1, 'year');

      //weho lives data
      if (showLivesWeHo===null) { // not already set to show
        // old data
        const lastVerifyDate = contact.custom_1464==="" ? null : moment(contact.custom_1464).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss');
        const oldData = !lastVerifyDate || moment(lastVerifyDate).isSameOrBefore(updateDate);

        showLivesWeHo = oldData;
      }

      //weho lives data
      if (showWorksWeHo===null) { // not already set to show
        // old data
        const lastVerifyDate = contact.custom_1465==="" ? null : moment(contact.custom_1465).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss');
        const oldData = !lastVerifyDate || moment(lastVerifyDate).isSameOrBefore(updateDate);

        showWorksWeHo = oldData;
      }

      //weho property data
      if (showPropertyWeHo===null) { // not already set to show
        // old data
        const lastVerifyDate = contact.custom_1466==="" ? null : moment(contact.custom_1466).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss');
        const oldData = !lastVerifyDate || moment(lastVerifyDate).isSameOrBefore(updateDate);

        showPropertyWeHo = oldData;
      }

      //weho school data
      if (showSchoolWeHo===null) { // not already set to show
        // old data
        const lastVerifyDate = contact.custom_1467==="" ? null : moment(contact.custom_1467).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss');
        const oldData = !lastVerifyDate || moment(lastVerifyDate).isSameOrBefore(updateDate);

        showSchoolWeHo = oldData;
      }

      //weho homeless data
      if (showHomelessWeHo===null) { // not already set to show
        // old data
        const lastVerifyDate = contact.custom_1468==="" ? null : moment(contact.custom_1468).tz("America/Los_Angeles").format('YYYY-MM-DD HH:mm:ss');
        const oldData = !lastVerifyDate || moment(lastVerifyDate).isSameOrBefore(updateDate);

        showHomelessWeHo = oldData;
      }
      
      // display this dialog if necessary - Remnant of when fields could be shown individually
      const showAtLeastOneField = showLivesWeHo || showWorksWeHo || showPropertyWeHo || showSchoolWeHo || showHomelessWeHo;
      this.setState({ somethingMissing, showLivesWeHo, showWorksWeHo, showPropertyWeHo, showSchoolWeHo, showHomelessWeHo });
      toggle(showAtLeastOneField);
    })
    .then(account => handleUpdateLoadingState(''))
    .catch((e) => {
      handleUpdateCurrentState(currentState);
      handleMessage(e.message, 'error');
      handleUpdateLoadingState('');
    });
  }

  submitForm = values => {

    const { handleMessage, handleUpdateCurrentState } = this.props;
    
    // const contact = {...values.contact};
    const contact = {...values.contact};
    const weho = {...values.weho};

    /**
     * Validate
     */

    const wehoErrors = {};

    // Profile
    
    /**
     * West Hollywood conditional validation
     */

    const {
      showLivesWeHo,
      showWorksWeHo,
      showPropertyWeHo,
      showSchoolWeHo,
      showHomelessWeHo,
    } = this.state;

    if (showLivesWeHo) {
      if (!weho.live_in_weho) { // live WeHo
        wehoErrors.live_in_weho = 'Required';
      } else if (weho.live_in_weho==="1") {
        if (!weho.live_in_weho_address)
          wehoErrors.live_in_weho_address = 'Required'; // live weho address
        if (!weho.live_in_weho_zip)
          wehoErrors.live_in_weho_zip = 'Required'; // live weho zip
      }
    }
  
    if (showWorksWeHo) {
      if (!weho.work_in_weho) { // work WeHo
        wehoErrors.work_in_weho = 'Required';
      } else if (weho.work_in_weho==="1") {
        if (!weho.work_in_weho_employer)
          wehoErrors.work_in_weho_employer = 'Required'; // work employer
        if (!weho.work_in_weho_address)
          wehoErrors.work_in_weho_address = 'Required'; // work address
        if (!weho.work_in_weho_zip)
          wehoErrors.work_in_weho_zip = 'Required'; // work address
      }
    }

    if (showPropertyWeHo) {
      if (!weho.property_in_weho) { // property WeHo
        wehoErrors.property_in_weho = 'Required';
      } else if (weho.property_in_weho==="1") {
        if (!weho.property_in_weho_address)
          wehoErrors.property_in_weho_address = 'Required'; // property address
        if (!weho.property_in_weho_zip)
          wehoErrors.property_in_weho_zip = 'Required'; // property zip
      }
    }

    if (showSchoolWeHo) {
      if (!weho.school_in_weho) { // school WeHo
        wehoErrors.school_in_weho = 'Required';
      } else if (weho.school_in_weho==="1") {
        if (!weho.school_in_weho_name)
          wehoErrors.school_in_weho_name = 'Required'; // school name
        if (!weho.school_in_weho_address)
          wehoErrors.school_in_weho_address = 'Required'; // school address
        if (!weho.school_in_weho_zip)
          wehoErrors.school_in_weho_zip = 'Required'; // school zip
      }
    }

    if (showHomelessWeHo) {
      if (!weho.homeless_in_weho) { // homeless WeHo
        wehoErrors.homeless_in_weho = 'Required';
      } else if (weho.homeless_in_weho==="1") {
        if (!weho.homeless_in_weho_address)
          wehoErrors.homeless_in_weho_address = 'Required'; // homeless address
        if (!weho.homeless_in_weho_zip)
          wehoErrors.homeless_in_weho_zip = 'Required'; // homeless zip
        if (!weho.homeless_in_weho_desc)
          wehoErrors.homeless_in_weho_desc = 'Required'; // homeless description
      }
    }

    /**
     * Aggrigate errors
     */
    const wehoErrorsKeys = Object.keys(wehoErrors);
    if (wehoErrorsKeys.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        weho: wehoErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    // Create weho verification activities

    const activityUrl = handleCiviURLBuild('Request', 'create');
    const wehoVerifyPromises = [];

    let wehoLivesHasError = false;
    let wehoWorksHasError = false;
    let wehoPropertyHasError = false;
    let wehoSchoolHasError = false;
    let wehoHomelessHasError = false;

    const formattedNow = moment().tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss');

    if (weho.live_in_weho==='1' && showLivesWeHo) {

      const livesJson = {
        activity_type_id: 171,
        target_contact_id: this.props.contactId,
        subject: "WeHo Resident Verification",
        activity_date_time: formattedNow,
        status_id: "Completed",
        custom_1437: 1, //Verification Type
        custom_1439: weho.live_in_weho_address, //Address
        custom_1440: weho.live_in_weho_zip, //Zip
        custom_1447: 2, //Signature Type
        custom_1444: formattedNow,
      };

      const livesFormData = new FormData();
      livesFormData.append('json', JSON.stringify(livesJson));
      wehoVerifyPromises.push(
        fetch(activityUrl, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: livesFormData,
        })
        .then(response => response.json())
        .then(livesResult => {
          if (livesResult.is_error) {
            wehoLivesHasError = true;
            wehoErrors.live_in_weho_address = livesResult.error_message;
            wehoErrors.live_in_weho_zip = livesResult.error_message;
          } else {
            this.setState({showLivesWeHo: false});
          }
          return livesResult;
        })
      );
    } else {
      this.setState({showLivesWeHo: false});
    }

    if (weho.work_in_weho==='1' && showWorksWeHo) {

      const worksJson = {
        activity_type_id: 171,
        target_contact_id: this.props.contactId,
        subject: "WeHo Work Verification",
        activity_date_time: formattedNow,
        status_id: "Completed",
        custom_1437: 1, //Verification Type
        custom_1439: weho.work_in_weho_address, //Address
        custom_1440: weho.work_in_weho_zip, //Zip
        custom_1438: weho.work_in_weho_employer, //Employer (as location name)
        custom_1447: 2, //Signature Type
        custom_1444: formattedNow,
      };

      const worksFormData = new FormData();
      worksFormData.append('json', JSON.stringify(worksJson));
      wehoVerifyPromises.push(
        fetch(activityUrl, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: worksFormData,
        })
        .then(response => response.json())
        .then(worksResult => {
          if (worksResult.is_error) {
            wehoWorksHasError = true;
            wehoErrors.work_in_weho_address = worksResult.error_message;
            wehoErrors.work_in_weho_zip = worksResult.error_message;
          } else {
            this.setState({showWorksWeHo: false});
          }
          return worksResult;
        })
      );
    } else {
      this.setState({showWorksWeHo: false});
    }

    if (weho.property_in_weho==='1' && showPropertyWeHo) {

      const propertyJson = {
        activity_type_id: 171,
        target_contact_id: this.props.contactId,
        subject: "WeHo Property Verification",
        activity_date_time: formattedNow,
        status_id: "Completed",
        custom_1437: 1, //Verification Type
        custom_1439: weho.property_in_weho_address, //Address
        custom_1440: weho.property_in_weho_zip, //Zip
        custom_1447: 2, //Signature Type
        custom_1444: formattedNow,
      };

      const propertyFormData = new FormData();
      propertyFormData.append('json', JSON.stringify(propertyJson));
      wehoVerifyPromises.push(
        fetch(activityUrl, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: propertyFormData,
        })
        .then(response => response.json())
        .then(propertyResult => {
          if (propertyResult.is_error) {
            wehoPropertyHasError = true;
            wehoErrors.property_in_weho_address = propertyResult.error_message;
            wehoErrors.property_in_weho_zip = propertyResult.error_message;
          } else {
            this.setState({showPropertyWeHo: false});
          }
          return propertyResult;
        })
      );
    } else {
      this.setState({showPropertyWeHo: false});
    }

    if (weho.school_in_weho==='1' && showSchoolWeHo) {

      const schoolJson = {
        activity_type_id: 171,
        target_contact_id: this.props.contactId,
        subject: "WeHo School Verification",
        activity_date_time: formattedNow,
        status_id: "Completed",
        custom_1437: 1, //Verification Type
        custom_1447: 2, //Signature Type
        custom_1438: weho.school_in_weho_name, //School Name (as location name)
        custom_1439: weho.school_in_weho_address, //Address
        custom_1440: weho.school_in_weho_zip, //Zip
        custom_1444: formattedNow,
      };

      const schoolFormData = new FormData();
      schoolFormData.append('json', JSON.stringify(schoolJson));
      wehoVerifyPromises.push(
        fetch(activityUrl, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: schoolFormData,
        })
        .then(response => response.json())
        .then(schoolResult => {
          if (schoolResult.is_error) {
            wehoSchoolHasError = false;
            wehoErrors.school_in_weho_address = schoolResult.error_message;
            wehoErrors.school_in_weho_zip = schoolResult.error_message;
          } else {
            this.setState({showSchoolWeHo: false});
          }
          return schoolResult;
        })
      );
    } else {
      this.setState({showSchoolWeHo: false});
    }

    if (weho.homeless_in_weho==='1' && showHomelessWeHo) {
      const homelessJson = {
        activity_type_id: 171,
        target_contact_id: this.props.contactId,
        subject: "WeHo Homeless Verification",
        activity_date_time: formattedNow,
        status_id: "Completed",
        custom_1437: 1, //Verification Type
        custom_1439: weho.homeless_in_weho_address, //Address
        custom_1440: weho.homeless_in_weho_zip, //Zip
        custom_1447: 2, //Signature Type
        details: weho.homeless_in_weho_desc, //Description  
        custom_1444: formattedNow,
      };

      const homelessFormData = new FormData();
      homelessFormData.append('json', JSON.stringify(homelessJson));
      wehoVerifyPromises.push(
        fetch(activityUrl, {
          method: "POST",
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }, 
          body: homelessFormData,
        })
        .then(response => response.json())
        .then(homelessResult => {
          if (homelessResult.is_error) {
            wehoHomelessHasError = false;
            wehoErrors.homeless_in_weho_address = homelessResult.error_message;
            wehoErrors.homeless_in_weho_zip = homelessResult.error_message;
          } else {
            this.setState({showHomelessWeHo: false});
          }
          return homelessResult;
        })
      );
    } else {
      this.setState({showHomelessWeHo: false});
    }

    return Promise.allSettled(wehoVerifyPromises)
    // .then(responses => responses.map(response => response.json()))
    .then(responses => {

      /**
       * Throw error if all submissions have errors, otherwise we'll
       * throw an error later after last check dates get updated for
       * correct submissions
       *  */

      if (wehoLivesHasError && wehoWorksHasError && wehoPropertyHasError && wehoSchoolHasError && wehoHomelessHasError)
        throw new Error('An error occured during form submission.');
      return responses;
    })
    .then(wehoResponses => {
      
      /**
      * Add in updated times
      */

      if (showLivesWeHo && !wehoLivesHasError)
        contact.custom_1464 = formattedNow;
      if (showWorksWeHo && !wehoWorksHasError)
        contact.custom_1465 = formattedNow;
      if (showPropertyWeHo && !wehoPropertyHasError)
        contact.custom_1466 = formattedNow;
      if (showSchoolWeHo && !wehoSchoolHasError)
        contact.custom_1467 = formattedNow;
      if (showHomelessWeHo && !wehoHomelessHasError)
        contact.custom_1468 = formattedNow;
  
      contact.id = this.props.contactId;
      contact.contact_type = 'Individual';

      // Update last weho checked dates on contact record

      const formData = new FormData();
      formData.append('json', JSON.stringify(contact));
    
      const url = handleCiviURLBuild('Contact', 'create');

      return fetch(url, {
        method: "POST",
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        }, 
        body: formData,
      })
      .then(response => response.json())
      .then(json => {
        // console.log(json);
        if (json.is_error)
          throw new Error(json.error_message);
        if (wehoLivesHasError || wehoWorksHasError || wehoPropertyHasError || wehoSchoolHasError || wehoHomelessHasError)
          throw new Error('An error occured during form submission.')
        handleMessage('Account information saved', 'success');
        // reset what we are showing
        this.setState(initialState);
        handleUpdateCurrentState(''); // refresh this page
        return json;
      })
    })
    .catch(error => {
      handleMessage('Some information did not match requirements.', 'error');
      throw new SubmissionError({
        weho: wehoErrors,
      });
    });

  }

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    const { somethingMissing } = this.state;

    const { handleSubmit, pristine, submitting } = this.props;

    const {
      showLivesWeHo,
      showWorksWeHo,
      showPropertyWeHo,
      showSchoolWeHo,
      showHomelessWeHo,
    } = this.state;

    return (
      <Dialog 
        className="DataEnrichment DataEnrichmentWeHo"
        visible={open}
        maximizable={true}
        closable={true}
        onHide={() => toggle(false)}
        header="We need to know a little more about you"
      >
        <Form onSubmit={handleSubmit(this.submitForm)}>
          <div className="p-grid">
            <div className="p-col p-col-12 boxed">
              <fieldset>
                <legend>Please update your information</legend>
                <p><em>
                  We work with many funding sources to provide free and low cost 
                  services including the City of West Hollywood. As part of our 
                  agreement with the City, we must verify eligibility for funding.
                </em></p>
                <WeHo
                  {...this.props}
                  show={{
                    wehoLives: showLivesWeHo===true,
                    wehoWorks: showWorksWeHo===true,
                    wehoProperty: showPropertyWeHo===true,
                    wehoSchool: showSchoolWeHo===true,
                    wehoHomeless: showHomelessWeHo===true,
                  }}
                />
              </fieldset>
            </div>
          </div>
          <div className="form-group">
            <Button type="submit" className="button" label="Update Account" disabled={(somethingMissing && pristine) || submitting} />
          </div>
        </Form>
      </Dialog>
    )
  }
}

const singleName = 'dataenrichment';
const formName = 'dataenrichment';

const mapStateToProps = (state) => {
  
  const { auth: { contactId }, form } = state;
  
  const single = state.single[singleName];

  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    contactId,
    ...single,
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),

    handleUpdateLoadingState: state => dispatch(updateLoadingState(singleName, state)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState(singleName, state)),
    handleSetSingleData: data => dispatch(setSingleData(singleName, data)),

    initializeDataEnrichment: data => dispatch(initialize(formName, data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
  })(DataEnrichmentWeHo)
);