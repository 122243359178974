const religions = [
  {  
    label: 'Non-Religious',
    value: '1',
  },
  {  
    label: 'Athiest',
    value: '2',
  },
  {  
    label: 'Jewish',
    value: '3',
  },
  {  
    label: 'Christian',
    value: '4',
  },
  {  
    label: 'Buddhist',
    value: '5',
  },
  {  
    label: 'Pagan',
    value: '6',
  },
  {  
    label: 'Muslim',
    value: '7',
  },
  {  
    label: 'Hindu',
    value: '8',
  },
  {  
    label: 'Catholic',
    value: '9',
  },
  {  
    label: 'Mormon',
    value: '10',
  },
  {  
    label: 'Jehovah\'s Witness',
    value: '11',
  },
  {  
    label: 'Other',
    value: '12',
  },
];

export default religions;