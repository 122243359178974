const phoneTypes = [
  {
    label: 'Mobile',
    value: '2',
  },
  {
    label: 'Work',
    value: '0',
  },
  {
    label: 'Phone',
    value: '1',
  },
  {
    label: 'Fax',
    value: '3',
  },
  {
    label: 'Pager',
    value: '4',
  },
  {
    label: 'Voicemail',
    value: '5',
  },
];

export default phoneTypes;