const gender = [
  {
    label: 'Female',
    value: '1',
  },
  {
    label: 'Male',
    value: '2',
  },
  {
    label: 'Gender Queer',
    value: '4',
  },
  {
    label: 'Gender Non Conforming',
    value: '6',
  },
  {
    label: 'Non-binary',
    value: "11",
  },
];

export default gender;