import constants from '../constants';

export const windowResize = (width, height)=> {
  const mobileMode = width<992;
  return {
    type: constants.WINDOW_RESIZE,
    value: {
      width,
      height,
      mobileMode,
    },
  };
}

export const menuOpen = menuOpen => {
  return {
    type: constants.MENU_OPEN,
    value: menuOpen,
  };
}
