import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import './Requests/styles.css';
// import Map from './App/Map';
// import ADARequest from './Requests/ADARequest';
import ParkingRequest from './Requests/ParkingRequest';
import TitleBar from './App/TitleBar';

class Requests extends Component {

  render() {

    return (
      <div className="Requests">
        <Route component={TitleBar} />
        <div className="mainArea">
          <div className="p-grid">
            {/* <div className="p-col p-lg-6 boxed">
              <fieldset>
                <legend>Americans with Disabilities Act</legend>
                <ADARequest />
              </fieldset>
            </div> */}
            <div className="p-col p-lg-6 boxed">
              <fieldset>
                <legend>Parking Request</legend>  
                <ParkingRequest />
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { mobileMode } = state.display;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Requests);