import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from 'store/actions/auth';
import { Field, Form, initialize, reduxForm } from 'redux-form';
import { InputText } from 'components/FormFields';
import { Button } from 'primereact/button';
import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import cx from 'classnames';
import './Login/styles.css';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render() {

    const { handleSubmit, isFetching, mobileMode } = this.props;
    const { showPassword } = this.state;

    return (
      <div className="Login">
        <Form onSubmit={handleSubmit}>
          <div className="p-grid p-justify-center gridContainer">
            <div className ="p-col p-md-4 hasBackground">
              <div className ={cx({ 'droppedCol': !mobileMode })}>
                <h1>Senior Services</h1>
                <p>
                  Use this portal to register for 
                  events and case management appointments.
                </p>
              </div>
            </div>
            <div className ="p-col p-md-4">
              <div className ={cx({ 'droppedColSignIn': !mobileMode })}>
                <div className="floatingBox">
                  <h2 className="textCenter">Sign in</h2>
                  <Field
                    label="Username"
                    name="username" 
                    disabled={isFetching}
                    component={InputText}
                  />
                  <Field
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    disabled={isFetching}
                    component={InputText}
                  />
                  <div className="p-grid p-justify-between p-align-center">
                    <div className={mobileMode ? "p-col-12" : "p-col-6"}>
                      <PrimeCheckbox
                        id="show_password"
                        checked={showPassword}
                        onChange={e => this.setState({ showPassword: e.checked})}
                      />
                      <label htmlFor="show_password" className="p-checkbox-label">Show Password</label>  
                    </div>
                    <div className={mobileMode ? "p-col-12 textCenter" : "p-col-6 textRight"}>
                      <Link to="/forgot-password">Forgot Username / Password?</Link>
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      label="Sign In"
                      type="submit"
                      className="button"
                      disabled={isFetching}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className ="p-col p-md-4">
              <div className ={cx({ 'droppedCol': !mobileMode })}>
                <div className="floatingBox">
                  <h2 className="textCenter">First time user?</h2>
                  <Link to="/register">
                    <Button
                      label="Register"
                      className="button p-button-warning"
                      disabled={isFetching}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

const formName = 'login';

const validate = val => {
  const errors = {};
 
  if (!val.username) 
    errors.username = 'Required';

  if (!val.password) 
    errors.password = 'Required';

  return errors;
}

const mapStateToProps = (state) => {
  const { isFetching } = state.auth;
  const { mobileMode } = state.display;
  return {
    isFetching,
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: async data => {
      const success = await dispatch(login(data.username, data.password));
      dispatch(initialize(formName, success ? {} : { username: data.username }));
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    validate,
  })(Login)
);