import initialState, { defaultState } from "../initialState";
import constants from "../constants";
import { cloneDeep } from 'lodash-es';

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const listReducer = (state = initialState.list, action) => {
  // console.log("Running action:", action);
  const newState = {...state};
  
  switch(action.type)
  {
    case constants.LIST_CURRENT_PAGE:
      newState[action.value.list].currentPage = action.value.currentPage;
      return newState;
    case constants.LIST_PAGE_SIZE:
      newState[action.value.list].pageSize = action.value.pageSize;
      newState[action.value.list].currentPage = action.value.currentPage;
      return newState;
    case constants.LIST_SORTING:
      newState[action.value.list].sortBy = action.value.sortBy;
      return newState;
    case constants.LIST_DATES:
      const { startDate, endDate, date } = action.value;
      newState[action.value.list].startDate = startDate;
      newState[action.value.list].endDate = endDate;
      newState[action.value.list].date = date;
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_SEARCH_VALUE:
      newState[action.value.list].searchValue = action.value.searchValue;
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_LOCATIONS:
      newState[action.value.list].locations = [...action.value.locations];
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_STATUSES:
      newState[action.value.list].statuses = [...action.value.statuses];
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_SPECIAL_VALUE:
      newState[action.value.list].specialValue = action.value.specialValue;
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_LOADING_TABLE_STATE:
      newState[action.value.list].isFetching = action.value.loadingListState.length>0;
      newState[action.value.list].loadingListState = action.value.loadingListState;
      return newState;
    case constants.LIST_TABLE_STATE:
      newState[action.value.list].listState = action.value.listState;
      // if (action.value.listState.length===0) {
      //   newState[action.value.list].data = null;
      //   newState[action.value.list].count = null;
      // }
      return newState;
    case constants.LIST_DATA:
      newState[action.value.list].data = action.value.response.data ? cloneDeep(action.value.response.data) : null;
      newState[action.value.list].count = action.value.response.total;
      return newState;
    case constants.LIST_DATA_UPDATE:
      if (newState[action.value.list].data===null) return newState;
      const { updates, updateFunction } = action.value;
      const updatedData = newState[action.value.list].data.map(record => {
        updates.forEach(update => record = updateFunction(record, update));
        return record;
      });
      newState[action.value.list].data = updatedData;
      return newState;
    case constants.LOGOUT:
      return {...defaultState.list};
    default:
      return newState;
  }
}

export default listReducer;