// import '@babel/polyfill';
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'index.css';
import App from 'views/App';
import store from 'store';
import * as serviceWorker from 'serviceWorker';

ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      {/* using Route instead of <App /> so that props is populated with route vars */}
      <Route path='*' component={App} />
    </HashRouter>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
