import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

class CancelAppointment extends Component {

  render() {

    const { activity, open, toggle, onContinue } = this.props;
    
    if (!activity)
      return null;

    const { date_display } = activity;

    return (
      <Dialog 
        className="CancelAppointment"
        visible={open}
        onHide={() => toggle(false)}
        header="Cancel Appointment?"
      >
        <p>Are you sure you want to cancel your Case Management appointment at "{date_display}"?</p>
        <div className="p-grid p-justify-center">
          <div className="p-col-fixed">
            <Button onClick={() => toggle(false)} label="No" />
          </div>
          <div className="p-col-fixed">&nbsp;</div>
          <div className="p-col-fixed">
            <Button onClick={onContinue} label="Yes" className="p-button-warning" />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default CancelAppointment;