import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export default () => (
  <div 
    style={{
      position:'fixed',
      top: 0,
      left: 0,
      background: 'rgba(255,255,255,0.5)',
      width: '100%',
      height: '100%',
      zIndex: 9999,
    }}
  >
    <div className="p-grid p-justify-center p-align-center" style={{ height: '100%' }}>
      <div className="p-col-fixed" style={{ width: 100 }}>
        <ProgressSpinner />
      </div>
    </div>
  </div>
)