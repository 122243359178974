
// colors from grommet (https://v2.grommet.io/color)
const statuses = [
  {
    label: 'Needs RSVP',
    value: 'rsvp',
    color: '#3D138D', // neutral-2
    // cursor: 'pointer',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
    color: '#00C781', // status-ok
    // cursor: 'pointer',
  },
  {
    label: 'Waitlist',
    value: 'waitlisted',
    color: '#FFAA15', // status-warning
    // cursor: 'pointer',
  },
  {
    label: 'Unavailable',
    value: 'unavailable',
    color: '#FF4040', // status-error
    // cursor: 'not-allowed',
  },
  {
    label: 'RSVP Pending',
    value: 'rsvp_pending',
    color: '#00739D', // neutral-3
    // cursor: 'pointer',
  },
  {
    label: 'Closed Group',
    value: 'closed_group',
    color: '#CCCCCC', // status-disabled
    // cursor: 'not-allowed',
  },
  {
    label: 'RSVP Cancelled',
    value: 'cancelled',
    color: '#777777', // dark-3
    // cursor: 'not-allowed',
  },
];

export default statuses;