import { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';

class Logout extends Component {

  componentDidMount() {
    this.props.handleLogout();
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { auth, display } = state;
  const { loggedIn, testMode } = auth;
  const { mobileMode } = display;
  return {
    loggedIn,
    mobileMode,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Logout);